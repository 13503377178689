export function IconNotifyBell({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 16 16" fill="none" height={size} width={size} {...other}>
      <g id="fi_8842478">
        <path
          id="Vector"
          d="M12.2126 8.64464V7.00463C12.2413 6.02288 11.9175 5.0633 11.2997 4.29973C10.6819 3.53615 9.81114 3.01908 8.84503 2.84218C9.01881 2.63956 9.11457 2.38157 9.11508 2.11463C9.07021 0.644905 6.93233 0.638042 6.88508 2.11466C6.8856 2.38156 6.98134 2.63952 7.15508 2.84213C6.18892 3.01896 5.31806 3.53602 4.70031 4.29963C4.08256 5.06325 3.75877 6.02289 3.78764 7.00467L3.78758 8.64213C3.72006 9.52771 3.40183 10.3758 2.87008 11.0871C2.68252 11.3115 2.57986 11.5947 2.58008 11.8871C2.59758 12.2946 2.83758 12.8296 3.87258 13.0871C4.65318 13.3267 5.46163 13.4637 6.27758 13.4946C6.40258 13.5021 6.53508 13.5096 6.67258 13.5146C7.60238 13.5494 8.77308 13.5552 9.72258 13.4921C10.5385 13.463 11.3471 13.3269 12.1276 13.0871C13.2341 12.8569 13.8025 11.977 13.1326 11.0871C12.6016 10.376 12.2827 9.52928 12.2126 8.64464ZM8.59008 2.28963C8.5473 2.43062 8.45539 2.55159 8.33104 2.63059C8.20669 2.70959 8.05813 2.74139 7.91233 2.72022C7.76653 2.69904 7.63316 2.62629 7.53642 2.51518C7.43968 2.40406 7.38598 2.26194 7.38508 2.11461C7.38454 2.02595 7.40314 1.93822 7.43963 1.85742C7.47612 1.77661 7.52963 1.70464 7.5965 1.64642C7.66337 1.5882 7.74203 1.54511 7.82709 1.52009C7.91215 1.49508 8.0016 1.48872 8.08935 1.50147C8.17709 1.51421 8.26104 1.54575 8.33547 1.59393C8.4099 1.64212 8.47304 1.7058 8.52059 1.78064C8.56813 1.85548 8.59895 1.9397 8.61095 2.02754C8.62294 2.11539 8.61582 2.20479 8.59008 2.28963Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M9.22086 14.0202C8.30263 14.0465 7.18789 14.0573 6.25586 13.9927C6.34156 14.3921 6.56168 14.75 6.87945 15.0066C7.19722 15.2632 7.59342 15.4031 8.00187 15.4028C8.41032 15.4025 8.80632 15.2621 9.12372 15.005C9.44112 14.7479 9.66073 14.3897 9.74586 13.9902C9.57768 14.0027 9.40399 14.013 9.22086 14.0202Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M1.36133 5.7627C1.29503 5.7627 1.23144 5.78904 1.18456 5.83592C1.13767 5.88281 1.11133 5.94639 1.11133 6.0127V8.86426C1.11133 8.93056 1.13767 8.99415 1.18455 9.04103C1.23144 9.08792 1.29502 9.11426 1.36133 9.11426C1.42763 9.11426 1.49122 9.08792 1.5381 9.04103C1.58499 8.99415 1.61133 8.93056 1.61133 8.86426V6.0127C1.61133 5.94639 1.58499 5.8828 1.5381 5.83592C1.49122 5.78903 1.42763 5.7627 1.36133 5.7627Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M2.68164 8.15078V6.72501C2.68071 6.65931 2.65396 6.59663 2.60718 6.5505C2.5604 6.50437 2.49734 6.47852 2.43164 6.47852C2.36594 6.47852 2.30288 6.50437 2.2561 6.5505C2.20932 6.59663 2.18257 6.65931 2.18164 6.72501V8.15078C2.18257 8.21648 2.20932 8.27916 2.2561 8.32529C2.30288 8.37142 2.36594 8.39727 2.43164 8.39727C2.49734 8.39727 2.5604 8.37142 2.60718 8.32529C2.65396 8.27916 2.68071 8.21648 2.68164 8.15078Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M14.6387 5.7627C14.5724 5.7627 14.5088 5.78903 14.4619 5.83592C14.415 5.8828 14.3887 5.94639 14.3887 6.0127V8.86426C14.3887 8.93056 14.415 8.99415 14.4619 9.04103C14.5088 9.08792 14.5724 9.11426 14.6387 9.11426C14.705 9.11426 14.7686 9.08792 14.8154 9.04103C14.8623 8.99415 14.8887 8.93056 14.8887 8.86426V6.0127C14.8887 5.94639 14.8623 5.88281 14.8154 5.83592C14.7686 5.78904 14.705 5.7627 14.6387 5.7627Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M13.5684 6.47559C13.5021 6.47559 13.4385 6.50193 13.3916 6.54881C13.3447 6.5957 13.3184 6.65928 13.3184 6.72559V8.15137C13.3184 8.21767 13.3447 8.28126 13.3916 8.32814C13.4385 8.37503 13.5021 8.40137 13.5684 8.40137C13.6347 8.40137 13.6983 8.37503 13.7451 8.32814C13.792 8.28126 13.8184 8.21767 13.8184 8.15137V6.72559C13.8184 6.65928 13.792 6.59569 13.7451 6.54881C13.6983 6.50192 13.6347 6.47559 13.5684 6.47559Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
