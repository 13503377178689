export function IconCake({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 20 20" fill="none" height={size} width={size} {...other}>
      <path
        d="M18.3307 18.9583H1.66406C1.3224 18.9583 1.03906 18.6749 1.03906 18.3333C1.03906 17.9916 1.3224 17.7083 1.66406 17.7083H18.3307C18.6724 17.7083 18.9557 17.9916 18.9557 18.3333C18.9557 18.6749 18.6724 18.9583 18.3307 18.9583Z"
        fill={color}
      />
      <path
        d="M17.4104 18.9583C17.0687 18.9583 16.7854 18.6749 16.7854 18.3333V10.8333C16.7854 9.79992 15.8187 8.95825 14.6354 8.95825H5.36875C4.18542 8.95825 3.21875 9.79992 3.21875 10.8333V18.3333C3.21875 18.6749 2.93542 18.9583 2.59375 18.9583C2.25208 18.9583 1.96875 18.6749 1.96875 18.3333V10.8333C1.96875 9.10825 3.49375 7.70825 5.36875 7.70825H14.6271C16.5021 7.70825 18.0271 9.10825 18.0271 10.8333V18.3333C18.0354 18.6749 17.7521 18.9583 17.4104 18.9583Z"
        fill={color}
      />
      <path
        d="M15.3661 8.95841C15.0245 8.95841 14.7411 8.67508 14.7411 8.33341V5.97508C14.7411 5.32508 14.1161 4.79175 13.3578 4.79175H6.64948C5.88281 4.79175 5.26615 5.32508 5.26615 5.97508V8.33341C5.26615 8.67508 4.98281 8.95841 4.64115 8.95841C4.29948 8.95841 4.00781 8.67508 4.00781 8.33341V5.97508C4.00781 4.63341 5.19115 3.54175 6.64115 3.54175H13.3495C14.7995 3.54175 15.9828 4.63341 15.9828 5.97508V8.33341C15.9911 8.67508 15.7078 8.95841 15.3661 8.95841Z"
        fill={color}
      />
      <path
        d="M14.4403 14.7916C13.4736 14.7916 12.6903 14.0083 12.6903 13.0416V12.7833C12.6903 12.5083 12.4653 12.2833 12.1903 12.2833C11.9153 12.2833 11.6903 12.5083 11.6903 12.7833V13.0416C11.6903 14.0083 10.9069 14.7916 9.94028 14.7916C8.97362 14.7916 8.19028 14.0083 8.19028 13.0416V12.7833C8.19028 12.5083 7.96528 12.2833 7.69028 12.2833C7.41528 12.2833 7.19028 12.5083 7.19028 12.7833V13.0416C7.19028 14.0083 6.40695 14.7916 5.44028 14.7916C4.47362 14.7916 3.69028 14.0083 3.69028 13.0416V12.7666C3.69028 12.4999 3.47362 12.2749 3.19862 12.2666H2.89028C2.54862 12.2583 2.26528 11.9749 2.27362 11.6333C2.28195 11.2916 2.55695 11.0166 2.89862 11.0166H2.90695H3.21528C4.16528 11.0333 4.94028 11.8166 4.94028 12.7666V13.0416C4.94028 13.3166 5.16528 13.5416 5.44028 13.5416C5.71528 13.5416 5.94028 13.3166 5.94028 13.0416V12.7833C5.94028 11.8166 6.72362 11.0333 7.69028 11.0333C8.65695 11.0333 9.44028 11.8166 9.44028 12.7833V13.0416C9.44028 13.3166 9.66528 13.5416 9.94028 13.5416C10.2153 13.5416 10.4403 13.3166 10.4403 13.0416V12.7833C10.4403 11.8166 11.2236 11.0333 12.1903 11.0333C13.1569 11.0333 13.9403 11.8166 13.9403 12.7833V13.0416C13.9403 13.3166 14.1653 13.5416 14.4403 13.5416C14.7153 13.5416 14.9403 13.3166 14.9403 13.0416V12.7833C14.9403 11.8166 15.7236 11.0333 16.6903 11.0333H17.0653C17.4069 11.0333 17.6903 11.3166 17.6903 11.6583C17.6903 11.9999 17.4069 12.2833 17.0653 12.2833H16.6903C16.4153 12.2833 16.1903 12.5083 16.1903 12.7833V13.0416C16.1903 14.0083 15.4069 14.7916 14.4403 14.7916Z"
        fill={color}
      />
      <path
        d="M6.66406 4.79167C6.3224 4.79167 6.03906 4.50833 6.03906 4.16667V2.5C6.03906 2.15833 6.3224 1.875 6.66406 1.875C7.00573 1.875 7.28906 2.15833 7.28906 2.5V4.16667C7.28906 4.50833 7.00573 4.79167 6.66406 4.79167Z"
        fill={color}
      />
      <path
        d="M13.3359 4.79167C12.9943 4.79167 12.7109 4.50833 12.7109 4.16667V2.5C12.7109 2.15833 12.9943 1.875 13.3359 1.875C13.6776 1.875 13.9609 2.15833 13.9609 2.5V4.16667C13.9609 4.50833 13.6776 4.79167 13.3359 4.79167Z"
        fill={color}
      />
      <path
        d="M10 4.79175C9.65833 4.79175 9.375 4.50841 9.375 4.16675V1.66675C9.375 1.32508 9.65833 1.04175 10 1.04175C10.3417 1.04175 10.625 1.32508 10.625 1.66675V4.16675C10.625 4.50841 10.3417 4.79175 10 4.79175Z"
        fill={color}
      />
    </svg>
  );
}
