export function IconGlobal({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 25 24" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/global">
        <g id="global">
          <path
            id="Vector"
            d="M12.5 22.75C6.57 22.75 1.75 17.93 1.75 12C1.75 6.07 6.57 1.25 12.5 1.25C18.43 1.25 23.25 6.07 23.25 12C23.25 17.93 18.43 22.75 12.5 22.75ZM12.5 2.75C7.4 2.75 3.25 6.9 3.25 12C3.25 17.1 7.4 21.25 12.5 21.25C17.6 21.25 21.75 17.1 21.75 12C21.75 6.9 17.6 2.75 12.5 2.75Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M9.50023 21.75H8.50023C8.09023 21.75 7.75023 21.41 7.75023 21C7.75023 20.59 8.07023 20.26 8.48023 20.25C6.91023 14.89 6.91023 9.11 8.48023 3.75C8.07023 3.74 7.75023 3.41 7.75023 3C7.75023 2.59 8.09023 2.25 8.50023 2.25H9.50023C9.74023 2.25 9.97023 2.37 10.1102 2.56C10.2502 2.76 10.2902 3.01 10.2102 3.24C8.33023 8.89 8.33023 15.11 10.2102 20.77C10.2902 21 10.2502 21.25 10.1102 21.45C9.97023 21.63 9.74023 21.75 9.50023 21.75Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M15.5004 21.75C15.4204 21.75 15.3404 21.74 15.2604 21.71C14.8704 21.58 14.6504 21.15 14.7904 20.76C16.6704 15.11 16.6704 8.89 14.7904 3.23C14.6604 2.84 14.8704 2.41 15.2604 2.28C15.6604 2.15 16.0804 2.36 16.2104 2.75C18.2004 8.71 18.2004 15.27 16.2104 21.22C16.1104 21.55 15.8104 21.75 15.5004 21.75Z"
            fill={color}
          />
          <path
            id="Vector_4"
            d="M12.5 17.2C9.71 17.2 6.93 16.81 4.25 16.02C4.24 16.42 3.91 16.75 3.5 16.75C3.09 16.75 2.75 16.41 2.75 16V15C2.75 14.76 2.87 14.53 3.06 14.39C3.26 14.25 3.51 14.21 3.74 14.29C9.39 16.17 15.62 16.17 21.27 14.29C21.5 14.21 21.75 14.25 21.95 14.39C22.15 14.53 22.26 14.76 22.26 15V16C22.26 16.41 21.92 16.75 21.51 16.75C21.1 16.75 20.77 16.43 20.76 16.02C18.07 16.81 15.29 17.2 12.5 17.2Z"
            fill={color}
          />
          <path
            id="Vector_5"
            d="M21.4998 9.74999C21.4198 9.74999 21.3398 9.73999 21.2598 9.70999C15.6098 7.82999 9.37978 7.82999 3.72978 9.70999C3.32978 9.83999 2.90978 9.62999 2.77978 9.23999C2.65978 8.83999 2.86978 8.41999 3.25978 8.28999C9.21978 6.29999 15.7798 6.29999 21.7298 8.28999C22.1198 8.41999 22.3398 8.84999 22.1998 9.23999C22.1098 9.54999 21.8098 9.74999 21.4998 9.74999Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
