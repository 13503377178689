export function IconUpload({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 20 21" fill="none" height={size} width={size} {...other}>
      <g id="fi_1092220" clipPath="url(#clip0_3010_320583)">
        <g id="surface1">
          <path
            id="Vector"
            d="M12.8871 0.615509C12.7954 0.518921 12.6697 0.460938 12.5394 0.460938H4.7374C3.29773 0.460938 2.10938 1.64456 2.10938 3.08408V17.8377C2.10938 19.2773 3.29773 20.461 4.7374 20.461H15.3267C16.7664 20.461 17.9547 19.2773 17.9547 17.8377V6.12272C17.9547 5.99714 17.8968 5.87644 17.8147 5.78459L12.8871 0.615509ZM13.0272 2.16626L16.3268 5.63001H14.1818C13.5442 5.63001 13.0272 5.11793 13.0272 4.48026V2.16626ZM15.3267 19.4948H4.7374C3.83408 19.4948 3.07556 18.746 3.07556 17.8377V3.08408C3.07556 2.18076 3.8292 1.42713 4.7374 1.42713H12.061V4.48026C12.061 5.65412 13.008 6.5962 14.1818 6.5962H16.9886V17.8377C16.9886 18.746 16.2349 19.4948 15.3267 19.4948Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M14.0129 16.1621H6.06122C5.79556 16.1621 5.57812 16.3794 5.57812 16.6452C5.57812 16.9109 5.79556 17.1283 6.06122 17.1283H14.0178C14.2834 17.1283 14.5009 16.9109 14.5009 16.6452C14.5009 16.3794 14.2834 16.1621 14.0129 16.1621Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M7.56322 10.9635L9.55358 8.82334V14.0988C9.55358 14.3644 9.77102 14.5819 10.0367 14.5819C10.3025 14.5819 10.5198 14.3644 10.5198 14.0988V8.82334L12.5101 10.9635C12.6067 11.0649 12.7324 11.1181 12.8628 11.1181C12.9787 11.1181 13.0996 11.0746 13.1913 10.9876C13.3846 10.8041 13.3991 10.4997 13.2155 10.3065L10.3846 7.26785C10.2927 7.17126 10.1671 7.11328 10.0319 7.11328C9.8966 7.11328 9.77102 7.17126 9.67931 7.26785L6.84834 10.3065C6.66478 10.4997 6.67928 10.8088 6.87245 10.9876C7.07539 11.1712 7.37966 11.1567 7.56322 10.9635Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3010_320583">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
