export function IconLink({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 14 14" fill="none" height={size} width={size} {...other}>
      <g id="fi_3462381" clipPath="url(#clip0_1950_307956)">
        <path
          id="Vector"
          d="M3.39654 14C2.50218 14 1.65759 13.6456 1.00596 12.994C0.323656 12.3116 -0.032742 11.4176 0.00236733 10.4766C0.0386524 9.50473 0.47771 8.57114 1.2721 7.77675L2.70239 6.34063C2.91551 6.12661 3.26176 6.1259 3.47578 6.33904C3.68977 6.55216 3.69051 6.89844 3.47737 7.11243L2.04631 8.54937C0.886635 9.70904 0.777206 11.2184 1.77941 12.2206C2.78164 13.2229 4.29098 13.1134 5.44981 11.9545L7.41546 9.98882C8.01125 9.39306 8.33982 8.71278 8.3656 8.02158C8.38925 7.38803 8.14633 6.78316 7.6816 6.31843C7.52588 6.16273 7.35358 6.03061 7.16948 5.92574C6.90703 5.77626 6.81546 5.44231 6.96495 5.17989C7.11446 4.91744 7.44841 4.8259 7.7108 4.97536C7.97981 5.12859 8.23019 5.32027 8.45501 5.54506C9.13732 6.22737 9.49372 7.12137 9.45861 8.06241C9.42233 9.03428 8.98327 9.96791 8.18888 10.7623L6.22322 12.7279C5.42881 13.5223 4.49524 13.9613 3.52336 13.9977C3.48103 13.9992 3.43868 14 3.39654 14ZM7.03511 8.82007C7.1846 8.55763 7.093 8.22368 6.83058 8.07422C6.64647 7.96936 6.47415 7.83723 6.31846 7.68156C5.31626 6.67934 5.42569 5.16999 6.58457 4.01111L8.55023 2.04548C9.70913 0.886628 11.2184 0.777171 12.2206 1.77937C13.2228 2.78157 13.1134 4.29092 11.9537 5.45059L10.5227 6.88753C10.3095 7.10152 10.3102 7.4478 10.5242 7.66092C10.7382 7.87406 11.0845 7.8733 11.2976 7.65933L12.7279 6.22319C13.5223 5.4288 13.9614 4.4952 13.9977 3.52335C14.0328 2.58232 13.6764 1.68829 12.9941 1.00598C12.3117 0.323649 11.4173 -0.032694 10.4767 0.0023606C9.50482 0.0386457 8.57123 0.477703 7.77686 1.27209L5.81118 3.23772C5.01679 4.03211 4.57776 4.96571 4.54145 5.93756C4.50634 6.87859 4.86274 7.77262 5.54504 8.45495C5.76984 8.67972 6.02022 8.87137 6.28926 9.02463C6.37468 9.07328 6.46765 9.09641 6.55941 9.09641C6.74953 9.09638 6.93427 8.99707 7.03511 8.82007Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1950_307956">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
