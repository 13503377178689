export function IconFavoriteSolid({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 16 16" fill="none" height={size} width={size} {...other}>
      <g id="favorite">
        <path
          id="Vector"
          d="M8.00016 14.1171L7.0335 13.2371C3.60016 10.1238 1.3335 8.07046 1.3335 5.55046C1.3335 3.49712 2.94683 1.88379 5.00016 1.88379C6.16016 1.88379 7.2735 2.42379 8.00016 3.27712C8.72683 2.42379 9.84016 1.88379 11.0002 1.88379C13.0535 1.88379 14.6668 3.49712 14.6668 5.55046C14.6668 8.07046 12.4002 10.1238 8.96683 13.2438L8.00016 14.1171Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
