export function IconSmsTracking({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 20 21" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/sms-tracking">
        <g id="sms-tracking">
          <path
            id="Vector"
            d="M14.1641 18.2084H5.83073C5.48906 18.2084 5.20573 17.9251 5.20573 17.5834C5.20573 17.2417 5.48906 16.9584 5.83073 16.9584H14.1641C16.5474 16.9584 17.7057 15.8001 17.7057 13.4167V7.58341C17.7057 5.20008 16.5474 4.04175 14.1641 4.04175H5.83073C3.4474 4.04175 2.28906 5.20008 2.28906 7.58341C2.28906 7.92508 2.00573 8.20841 1.66406 8.20841C1.3224 8.20841 1.03906 7.92508 1.03906 7.58341C1.03906 4.54175 2.78906 2.79175 5.83073 2.79175H14.1641C17.2057 2.79175 18.9557 4.54175 18.9557 7.58341V13.4167C18.9557 16.4584 17.2057 18.2084 14.1641 18.2084Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M9.99705 11.225C9.29705 11.225 8.58872 11.0083 8.04705 10.5666L5.43871 8.48331C5.17205 8.26664 5.12205 7.87497 5.33872 7.60831C5.55538 7.34164 5.94704 7.29164 6.21371 7.50831L8.82205 9.59164C9.45538 10.1 10.5304 10.1 11.1637 9.59164L13.772 7.50831C14.0387 7.29164 14.4387 7.33331 14.647 7.60831C14.8637 7.87497 14.822 8.27498 14.547 8.48331L11.9387 10.5666C11.4054 11.0083 10.697 11.225 9.99705 11.225Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M6.66406 14.875H1.66406C1.3224 14.875 1.03906 14.5917 1.03906 14.25C1.03906 13.9083 1.3224 13.625 1.66406 13.625H6.66406C7.00573 13.625 7.28906 13.9083 7.28906 14.25C7.28906 14.5917 7.00573 14.875 6.66406 14.875Z"
            fill={color}
          />
          <path
            id="Vector_4"
            d="M4.16406 11.5417H1.66406C1.3224 11.5417 1.03906 11.2584 1.03906 10.9167C1.03906 10.5751 1.3224 10.2917 1.66406 10.2917H4.16406C4.50573 10.2917 4.78906 10.5751 4.78906 10.9167C4.78906 11.2584 4.50573 11.5417 4.16406 11.5417Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
