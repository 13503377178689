export function IconHome({ size = 24, color = "currentColor", ...other }) {
  return (
    // <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
    //   <g clipPath="url(#clip0_2052_455580)">
    //     <path
    //       d="M10.0025 19V14H14.0025V19C14.0025 19.55 14.4525 20 15.0025 20H18.0025C18.5525 20 19.0025 19.55 19.0025 19V12H20.7025C21.1625 12 21.3825 11.43 21.0325 11.13L12.6725 3.59997C12.2925 3.25997 11.7125 3.25997 11.3325 3.59997L2.97254 11.13C2.63254 11.43 2.84254 12 3.30254 12H5.00254V19C5.00254 19.55 5.45254 20 6.00254 20H9.00254C9.55254 20 10.0025 19.55 10.0025 19Z"
    //       fill={color}
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_2052_455580">
    //       <rect width="24" height="24" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <svg
      width={size}
      height={size}
      {...other}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.5" cy="27.5" r="27.5" fill="#ECF0F5" />
      <path
        d="M39.9905 27.4409L27.9905 14.1075C27.4852 13.5449 26.5145 13.5449 26.0092 14.1075L14.0092 27.4409C13.8367 27.6325 13.7236 27.87 13.6833 28.1246C13.6431 28.3793 13.6775 28.6401 13.7825 28.8755C13.9958 29.3569 14.4732 29.6662 14.9998 29.6662H17.6665V38.9995C17.6665 39.3532 17.807 39.6923 18.057 39.9424C18.3071 40.1924 18.6462 40.3329 18.9998 40.3329H22.9998C23.3535 40.3329 23.6926 40.1924 23.9426 39.9424C24.1927 39.6923 24.3332 39.3532 24.3332 38.9995V33.6662H29.6665V38.9995C29.6665 39.3532 29.807 39.6923 30.057 39.9424C30.3071 40.1924 30.6462 40.3329 30.9998 40.3329H34.9998C35.3535 40.3329 35.6926 40.1924 35.9426 39.9424C36.1927 39.6923 36.3332 39.3532 36.3332 38.9995V29.6662H38.9998C39.2581 29.6673 39.511 29.5933 39.7279 29.4531C39.9447 29.3129 40.1161 29.1126 40.2211 28.8767C40.3262 28.6408 40.3603 28.3795 40.3193 28.1245C40.2784 27.8696 40.1641 27.632 39.9905 27.4409Z"
        fill={color}
      />
    </svg>
  );
}
