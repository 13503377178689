export function IconDocumentDownload({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 14 16" fill="none" height={size} width={size} {...other}>
      <g id="surface1">
        <path
          id="Vector"
          d="M9.30971 0.0924075C9.23634 0.0151368 9.13576 -0.03125 9.03151 -0.03125H2.78992C1.63819 -0.03125 0.6875 0.915651 0.6875 2.06726V13.8701C0.6875 15.0219 1.63819 15.9688 2.78992 15.9688H11.2614C12.4131 15.9688 13.3638 15.0219 13.3638 13.8701V4.49818C13.3638 4.39771 13.3174 4.30116 13.2517 4.22767L9.30971 0.0924075ZM9.42177 1.33301L12.0614 4.10401H10.3455C9.83534 4.10401 9.42177 3.69434 9.42177 3.18421V1.33301ZM11.2614 15.1958H2.78992C2.06726 15.1958 1.46045 14.5968 1.46045 13.8701V2.06726C1.46045 1.34461 2.06336 0.741701 2.78992 0.741701H8.64882V3.18421C8.64882 4.1233 9.40639 4.87696 10.3455 4.87696H12.5908V13.8701C12.5908 14.5968 11.9879 15.1958 11.2614 15.1958Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M10.2068 12.5293H3.84546C3.63293 12.5293 3.45898 12.7031 3.45898 12.9158C3.45898 13.1283 3.63293 13.3022 3.84546 13.3022H10.2107C10.4232 13.3022 10.5972 13.1283 10.5972 12.9158C10.5972 12.7031 10.4232 12.5293 10.2068 12.5293Z"
          fill={color}
        />
        <path
          id="Vector_3"
          d="M6.74345 11.1422C6.81694 11.2195 6.9174 11.2659 7.02555 11.2659C7.13383 11.2659 7.2343 11.2195 7.30766 11.1422L9.57244 8.71131C9.71929 8.55665 9.70769 8.30933 9.55315 8.16639C9.39848 8.01954 9.15117 8.03101 9.00823 8.18567L7.41203 9.89771V5.67749C7.41203 5.46484 7.23808 5.29102 7.02555 5.29102C6.81303 5.29102 6.63908 5.46484 6.63908 5.67749V9.89771L5.04679 8.18567C4.89994 8.03113 4.65641 8.01954 4.50187 8.16639C4.34733 8.31324 4.33573 8.55677 4.48258 8.71131L6.74345 11.1422Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
