export function IconPeople({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/bold/people">
        <g id="people">
          <path
            id="Vector"
            d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M20.7896 14.7C19.6696 15.45 18.0996 15.73 16.6496 15.54C17.0296 14.72 17.2296 13.81 17.2396 12.85C17.2396 11.85 17.0196 10.9 16.5996 10.07C18.0796 9.87004 19.6496 10.15 20.7796 10.9C22.3596 11.94 22.3596 13.65 20.7896 14.7Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
            fill={color}
          />
          <path
            id="Vector_4"
            d="M6.54914 12.85C6.54914 13.82 6.75914 14.74 7.13914 15.57C5.72914 15.72 4.25914 15.42 3.17914 14.71C1.59914 13.66 1.59914 11.95 3.17914 10.9C4.24914 10.18 5.75914 9.88998 7.17914 10.05C6.76914 10.89 6.54914 11.84 6.54914 12.85Z"
            fill={color}
          />
          <path
            id="Vector_5"
            d="M12.1208 15.87C12.0408 15.86 11.9508 15.86 11.8608 15.87C10.0208 15.81 8.55078 14.3 8.55078 12.44C8.56078 10.54 10.0908 9 12.0008 9C13.9008 9 15.4408 10.54 15.4408 12.44C15.4308 14.3 13.9708 15.81 12.1208 15.87Z"
            fill={color}
          />
          <path
            id="Vector_6"
            d="M8.87078 17.94C7.36078 18.95 7.36078 20.61 8.87078 21.61C10.5908 22.76 13.4108 22.76 15.1308 21.61C16.6408 20.6 16.6408 18.94 15.1308 17.94C13.4208 16.79 10.6008 16.79 8.87078 17.94Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
