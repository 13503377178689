export function IconTalentVideo({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <path
        d="M19.9994 4.00047V3.20031C19.9994 2.75967 19.6402 2.40039 19.1995 2.40039H14.3995C13.9606 2.40039 13.5996 2.75967 13.5996 3.20031V6.40047C13.5996 6.83943 13.9606 7.20039 14.3995 7.20039H19.1995C19.6402 7.20039 19.9994 6.83943 19.9994 6.40047V5.60031L21.5995 7.20039V2.40039L19.9994 4.00047Z"
        fill={color}
      />
      <path
        d="M12.0004 6.40047V2.40039H4.00047C3.12063 2.40039 2.40039 3.12063 2.40039 4.00047V16.8004C2.40039 17.68 3.12063 18.4005 4.00047 18.4005H9.60039V20.0003H7.20039C6.31671 20.0003 5.60031 20.7167 5.60031 21.6004L15.2003 21.5997C15.2003 20.716 14.4842 19.9996 13.6005 19.9996H11.2005V18.4005H16.8004C17.68 18.4005 18.4005 17.68 18.4005 16.8004V8.80047H14.4004C13.0768 8.80047 12.0004 7.72383 12.0004 6.40047ZM10.4003 8.79951C11.284 8.79951 12.0004 9.51591 12.0004 10.3996C12.0004 11.2833 11.284 11.9997 10.4003 11.9997C9.51591 11.9997 8.80047 11.2833 8.80047 10.3996C8.80047 9.51591 9.51591 8.79951 10.4003 8.79951ZM7.20039 16.0005C7.20039 14.6747 8.27535 13.6005 9.60039 13.6005L11.2005 13.5995C12.526 13.5995 13.6005 14.6745 13.6005 15.9995L7.20039 16.0005Z"
        fill={color}
      />
    </svg>
  );
}
