export function IconClipboardText({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/bold/clipboard-text">
        <g id="clipboard-text">
          <path
            id="Vector"
            d="M14.3478 2H9.64781C8.60781 2 7.75781 2.84 7.75781 3.88V4.82C7.75781 5.86 8.59781 6.7 9.63781 6.7H14.3478C15.3878 6.7 16.2278 5.86 16.2278 4.82V3.88C16.2378 2.84 15.3878 2 14.3478 2Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M17.2391 4.81998C17.2391 6.40998 15.9391 7.70998 14.3491 7.70998H9.64906C8.05906 7.70998 6.75906 6.40998 6.75906 4.81998C6.75906 4.25998 6.15906 3.90998 5.65906 4.16998C4.24906 4.91998 3.28906 6.40998 3.28906 8.11998V17.53C3.28906 19.99 5.29906 22 7.75906 22H16.2391C18.6991 22 20.7091 19.99 20.7091 17.53V8.11998C20.7091 6.40998 19.7491 4.91998 18.3391 4.16998C17.8391 3.90998 17.2391 4.25998 17.2391 4.81998ZM12.3791 16.95H7.99906C7.58906 16.95 7.24906 16.61 7.24906 16.2C7.24906 15.79 7.58906 15.45 7.99906 15.45H12.3791C12.7891 15.45 13.1291 15.79 13.1291 16.2C13.1291 16.61 12.7891 16.95 12.3791 16.95ZM14.9991 12.95H7.99906C7.58906 12.95 7.24906 12.61 7.24906 12.2C7.24906 11.79 7.58906 11.45 7.99906 11.45H14.9991C15.4091 11.45 15.7491 11.79 15.7491 12.2C15.7491 12.61 15.4091 12.95 14.9991 12.95Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
