export function IconLike({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 20 20" fill="none" height={size} width={size} {...other}>
      <path
        d="M17.7974 8.80756C17.3026 8.1902 16.5278 7.80669 15.7754 7.80669H13.785C14.2329 7.06776 14.57 6.32418 14.7755 5.61611C15.089 4.5356 15.078 3.5937 14.7437 2.89223C14.3672 2.10213 13.6042 1.66699 12.5953 1.66699C12.3658 1.66699 12.159 1.81029 12.0716 2.0299C11.286 4.00227 9.237 6.83713 7.30649 8.70461C7.1095 7.99628 6.4782 7.47644 5.73141 7.47644H3.30619C2.40235 7.47644 1.66699 8.23766 1.66699 9.17325V16.6368C1.66699 17.5724 2.40235 18.3337 3.30619 18.3337H5.73141C6.28432 18.3337 6.77383 18.0485 7.07087 17.6131C7.52023 18.0606 8.11594 18.3337 8.7687 18.3337H14.8753C15.5504 18.3337 16.1516 18.0361 16.6139 17.4732C16.995 17.0091 17.2657 16.3821 17.3966 15.6601L18.2967 10.6967C18.4174 10.0308 18.2401 9.35987 17.7974 8.80756ZM6.23762 16.6369C6.23762 16.9258 6.01054 17.1609 5.73141 17.1609H3.30619C3.02706 17.1609 2.79994 16.9258 2.79994 16.6369V9.17329C2.79994 8.88434 3.02706 8.64927 3.30619 8.64927H5.73141C6.01054 8.64927 6.23762 8.88434 6.23762 9.17329V16.6369ZM17.1832 10.4803L16.2831 15.4437C16.1326 16.2739 15.6837 17.1609 14.8753 17.1609H8.76866C7.99776 17.1609 7.37061 16.4391 7.37061 15.5519V10.2105C9.50589 8.37557 11.8972 5.2241 12.9529 2.86863C13.5162 2.96729 13.6709 3.29189 13.7277 3.41108C14.0954 4.18259 13.7902 5.95904 12.2515 8.03623C12.1202 8.21336 12.0975 8.45232 12.193 8.6525C12.2884 8.85268 12.4853 8.97945 12.701 8.97945H15.7754C16.1932 8.97945 16.6447 9.20591 16.9256 9.55639C17.0837 9.75378 17.2569 10.0742 17.1832 10.4803Z"
        fill={color}
      />
    </svg>
  );
}
