export function IconCalendar1({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 18 18" fill="none" height={size} width={size} {...other}>
      <g id="fi_956926" clipPath="url(#clip0_1913_34618)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M15.3506 8.23086V3.69112C15.3506 2.61217 14.467 1.721 13.388 1.721H13.1436V1.41326C13.1436 0.643439 12.5195 0.0193597 11.7497 0.0193597C10.9798 0.0193597 10.3558 0.643439 10.3558 1.41326V1.721H5.24481V1.41326C5.24481 0.632753 4.61205 0 3.83155 0C3.05104 0 2.41829 0.632753 2.41829 1.41326V1.721H2.20707C1.12808 1.721 0.25 2.61217 0.25 3.69112V14.1153C0.25 15.1943 1.12808 16.0859 2.20707 16.0859H8.34774C9.36014 17.2969 10.8566 17.9977 12.435 18C15.3661 18 17.7537 15.6119 17.7537 12.6808C17.7538 10.823 16.7832 9.18157 15.3506 8.23086ZM11.1302 1.41326C11.1242 1.07601 11.3927 0.797775 11.73 0.791812C11.7354 0.791735 11.7408 0.791696 11.7462 0.791735C12.0866 0.788134 12.3656 1.06118 12.3692 1.40164C12.3692 1.40551 12.3692 1.40939 12.3692 1.41326V2.9213H11.1302V1.41326ZM3.19268 1.41326C3.19635 1.06629 3.48063 0.788018 3.8276 0.791696C3.82822 0.791696 3.8288 0.791696 3.82938 0.791735C4.17762 0.791735 4.47042 1.06506 4.47042 1.41326V2.9213H3.19268V1.41326ZM1.02439 3.69112C1.02439 3.03913 1.55508 2.49539 2.20707 2.49539H2.41829V3.3246C2.41829 3.53841 2.59686 3.69569 2.81071 3.69569H4.84812C5.06193 3.69569 5.24481 3.53841 5.24481 3.3246V2.49539H10.3558V3.3246C10.35 3.52382 10.5069 3.68996 10.7061 3.69569C10.7132 3.69588 10.7204 3.69588 10.7275 3.69569H12.7649C12.9672 3.70243 13.1367 3.54387 13.1434 3.34156C13.1436 3.33591 13.1436 3.33026 13.1436 3.3246V2.49539H13.388C14.0444 2.50201 14.5737 3.03471 14.5762 3.69112V4.93471H1.02439V3.69112ZM2.20707 15.3115C1.55508 15.3115 1.02439 14.7673 1.02439 14.1153V5.7091H14.5762V7.80978C13.9022 7.51385 13.174 7.36122 12.4379 7.36149C9.50688 7.36149 7.12072 9.75307 7.12072 12.6842C7.11925 13.6051 7.35726 14.5105 7.81144 15.3115H2.20707ZM12.435 17.2187C9.92684 17.2187 7.89356 15.1854 7.89356 12.6773C7.89356 10.1691 9.92684 8.13584 12.435 8.13584C14.9432 8.13584 16.9764 10.1691 16.9764 12.6773V12.6773C16.9736 15.1843 14.942 17.2159 12.435 17.2187Z"
              fill={color}
            />
          </g>
        </g>
        <g id="Group_3">
          <g id="Group_4">
            <path
              id="Vector_2"
              d="M14.2558 12.6789H12.5595V10.3491C12.5595 10.1353 12.3862 9.96191 12.1724 9.96191C11.9585 9.96191 11.7852 10.1353 11.7852 10.3491V13.0657C11.7886 13.281 11.9643 13.4536 12.1796 13.4532H14.2558C14.4697 13.4532 14.643 13.2799 14.643 13.066C14.643 12.8522 14.4697 12.6789 14.2558 12.6789Z"
              fill={color}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1913_34618">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
