export function IconFavorite({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 16 16" fill="none" height={size} width={size} {...other}>
      <path
        d="M10.9999 1.88379C9.83992 1.88379 8.72659 2.42379 7.99992 3.27712C7.27325 2.42379 6.15992 1.88379 4.99992 1.88379C2.94659 1.88379 1.33325 3.49712 1.33325 5.55046C1.33325 8.07046 3.59992 10.1238 7.03325 13.2438L7.99992 14.1171L8.96659 13.2371C12.3999 10.1238 14.6666 8.07046 14.6666 5.55046C14.6666 3.49712 13.0533 1.88379 10.9999 1.88379ZM8.06659 12.2505L7.99992 12.3171L7.93325 12.2505C4.75992 9.37712 2.66659 7.47712 2.66659 5.55046C2.66659 4.21712 3.66659 3.21712 4.99992 3.21712C6.02659 3.21712 7.02659 3.87712 7.37992 4.79046H8.62659C8.97325 3.87712 9.97325 3.21712 10.9999 3.21712C12.3333 3.21712 13.3333 4.21712 13.3333 5.55046C13.3333 7.47712 11.2399 9.37712 8.06659 12.2505Z"
        fill={color}
      />
    </svg>
  );
}
