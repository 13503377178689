export function IconCalendar2({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 16 16" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/calendar-2">
        <g id="calendar-2">
          <path
            id="Vector"
            d="M5.33203 3.83301C5.0587 3.83301 4.83203 3.60634 4.83203 3.33301V1.33301C4.83203 1.05967 5.0587 0.833008 5.33203 0.833008C5.60536 0.833008 5.83203 1.05967 5.83203 1.33301V3.33301C5.83203 3.60634 5.60536 3.83301 5.33203 3.83301Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M10.668 3.83301C10.3946 3.83301 10.168 3.60634 10.168 3.33301V1.33301C10.168 1.05967 10.3946 0.833008 10.668 0.833008C10.9413 0.833008 11.168 1.05967 11.168 1.33301V3.33301C11.168 3.60634 10.9413 3.83301 10.668 3.83301Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M13.6654 6.55957H2.33203C2.0587 6.55957 1.83203 6.3329 1.83203 6.05957C1.83203 5.78624 2.0587 5.55957 2.33203 5.55957H13.6654C13.9387 5.55957 14.1654 5.78624 14.1654 6.05957C14.1654 6.3329 13.9387 6.55957 13.6654 6.55957Z"
            fill={color}
          />
          <path
            id="Vector_4"
            d="M10.6667 15.1663H5.33333C2.9 15.1663 1.5 13.7663 1.5 11.333V5.66634C1.5 3.23301 2.9 1.83301 5.33333 1.83301H10.6667C13.1 1.83301 14.5 3.23301 14.5 5.66634V11.333C14.5 13.7663 13.1 15.1663 10.6667 15.1663ZM5.33333 2.83301C3.42667 2.83301 2.5 3.75967 2.5 5.66634V11.333C2.5 13.2397 3.42667 14.1663 5.33333 14.1663H10.6667C12.5733 14.1663 13.5 13.2397 13.5 11.333V5.66634C13.5 3.75967 12.5733 2.83301 10.6667 2.83301H5.33333Z"
            fill={color}
          />
          <path
            id="Vector_5"
            d="M5.66667 9.66635C5.58 9.66635 5.49333 9.64636 5.41333 9.61302C5.33333 9.57969 5.26001 9.53301 5.19334 9.47301C5.13334 9.40634 5.08666 9.33302 5.05332 9.25302C5.01999 9.17302 5 9.08635 5 8.99968C5 8.82635 5.07334 8.65302 5.19334 8.52635C5.26001 8.46635 5.33333 8.41967 5.41333 8.38634C5.53333 8.33301 5.66667 8.31967 5.80001 8.34634C5.84001 8.35301 5.88 8.36634 5.92 8.38634C5.96 8.39967 6 8.41969 6.04 8.44636C6.07333 8.47302 6.10666 8.49969 6.13999 8.52635C6.16666 8.55969 6.19999 8.59302 6.21999 8.62635C6.24666 8.66635 6.26668 8.70635 6.28001 8.74635C6.30001 8.78635 6.31334 8.82634 6.32001 8.86634C6.32667 8.91301 6.33333 8.95302 6.33333 8.99968C6.33333 9.17302 6.25999 9.34634 6.13999 9.47301C6.01333 9.59301 5.84 9.66635 5.66667 9.66635Z"
            fill={color}
          />
          <path
            id="Vector_6"
            d="M7.9987 9.66658C7.82536 9.66658 7.65204 9.59324 7.52537 9.47324C7.4987 9.43991 7.47204 9.40658 7.44537 9.37325C7.41871 9.33325 7.39869 9.29325 7.38536 9.25325C7.36536 9.21325 7.35202 9.17326 7.34536 9.13326C7.33869 9.08659 7.33203 9.04658 7.33203 8.99992C7.33203 8.91325 7.35202 8.82658 7.38536 8.74658C7.41869 8.66658 7.46537 8.59325 7.52537 8.52659C7.71204 8.33992 8.01203 8.27991 8.25203 8.38657C8.3387 8.41991 8.40536 8.46659 8.47203 8.52659C8.59203 8.65325 8.66536 8.82658 8.66536 8.99992C8.66536 9.04658 8.65871 9.08659 8.65204 9.13326C8.64537 9.17326 8.63204 9.21325 8.61204 9.25325C8.59871 9.29325 8.57869 9.33325 8.55202 9.37325C8.52536 9.40658 8.49869 9.43991 8.47203 9.47324C8.40536 9.53324 8.3387 9.57992 8.25203 9.61326C8.17203 9.64659 8.08536 9.66658 7.9987 9.66658Z"
            fill={color}
          />
          <path
            id="Vector_7"
            d="M5.66667 11.9996C5.58 11.9996 5.49333 11.9796 5.41333 11.9463C5.33333 11.9129 5.26001 11.8663 5.19334 11.8063C5.13334 11.7396 5.08666 11.6729 5.05332 11.5863C5.01999 11.5063 5 11.4196 5 11.3329C5 11.1596 5.07334 10.9863 5.19334 10.8596C5.26001 10.7996 5.33333 10.7529 5.41333 10.7196C5.66 10.6129 5.95333 10.6729 6.13999 10.8596C6.16666 10.8929 6.19999 10.9263 6.21999 10.9596C6.24666 10.9996 6.26668 11.0396 6.28001 11.0796C6.30001 11.1196 6.31334 11.1596 6.32001 11.2063C6.32667 11.2463 6.33333 11.2929 6.33333 11.3329C6.33333 11.5063 6.25999 11.6796 6.13999 11.8063C6.01333 11.9263 5.84 11.9996 5.66667 11.9996Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
