export function IconLogOut({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 18 20" fill="none" height={size} width={size} {...other}>
      <g id="Group">
        <path
          id="Vector"
          d="M9.00128 19.7306C7.1285 19.7337 5.30763 19.1153 3.82409 17.9723C2.34055 16.8293 1.2782 15.2264 0.803559 13.4147C0.32892 11.6031 0.468823 9.68516 1.20134 7.96158C1.93386 6.238 3.21758 4.8062 4.85128 3.8906C5.02189 3.79923 5.22121 3.77735 5.40758 3.82954C5.59395 3.88172 5.75293 4.00392 5.85128 4.1706C5.9369 4.34333 5.95462 4.54189 5.90092 4.72705C5.84723 4.9122 5.72603 5.07048 5.56128 5.1706C4.216 5.92199 3.15623 7.09628 2.54634 8.51135C1.93645 9.92642 1.81051 11.5032 2.18807 12.9971C2.56563 14.4911 3.42558 15.8187 4.63455 16.7741C5.84352 17.7294 7.33396 18.2592 8.8747 18.2812C10.4154 18.3033 11.9204 17.8163 13.1562 16.8958C14.3919 15.9753 15.2895 14.6728 15.7095 13.1903C16.1296 11.7077 16.0488 10.128 15.4796 8.69611C14.9103 7.2642 13.8846 6.06011 12.5613 5.2706C12.4779 5.21742 12.4058 5.14833 12.3491 5.06728C12.2924 4.98623 12.2522 4.89481 12.2309 4.79824C12.2095 4.70166 12.2074 4.60183 12.2246 4.50443C12.2419 4.40704 12.2781 4.31399 12.3313 4.2306C12.3845 4.14721 12.4536 4.07511 12.5346 4.01843C12.6157 3.96174 12.7071 3.92157 12.8036 3.90021C12.9987 3.85708 13.2029 3.89319 13.3713 4.0006C14.9667 4.95 16.2055 6.39782 16.8967 8.12086C17.5879 9.8439 17.6932 11.7465 17.1962 13.5352C16.6993 15.324 15.6278 16.8997 14.1468 18.0192C12.6659 19.1388 10.8578 19.7402 9.00128 19.7306Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M9 7.80098C8.80189 7.79839 8.61263 7.71854 8.47253 7.57844C8.33244 7.43835 8.25259 7.24908 8.25 7.05098V1.00098C8.25 0.802064 8.32902 0.611299 8.46967 0.470646C8.61032 0.329994 8.80109 0.250977 9 0.250977C9.19891 0.250977 9.38968 0.329994 9.53033 0.470646C9.67098 0.611299 9.75 0.802064 9.75 1.00098V7.00098C9.7554 7.10326 9.74006 7.20558 9.70492 7.30178C9.66978 7.39799 9.61556 7.4861 9.5455 7.56082C9.47545 7.63555 9.39102 7.69534 9.29728 7.73661C9.20353 7.77788 9.10242 7.79977 9 7.80098Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
