export function IconMenuSignin({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <path
        d="M10.7977 2.8673C11.1281 2.57046 11.5565 2.40625 12.0007 2.40625C12.4448 2.40625 12.8733 2.57046 13.2037 2.8673L19.8037 8.7965C19.9915 8.96538 20.1417 9.1719 20.2444 9.40263C20.3472 9.63336 20.4002 9.88313 20.4001 10.1357V11.2805C19.9421 11.0162 19.4316 10.8559 18.9047 10.8112C18.3779 10.7664 17.8476 10.8382 17.3517 11.0215C16.8557 11.2047 16.4061 11.495 16.035 11.8716C15.6638 12.2482 15.3801 12.7019 15.2041 13.2005H10.2001C10.041 13.2005 9.88836 13.2637 9.77583 13.3762C9.66331 13.4888 9.6001 13.6414 9.6001 13.8005V18.6005C9.6001 19.0779 9.41046 19.5357 9.07289 19.8733C8.73532 20.2109 8.27749 20.4005 7.8001 20.4005H5.4001C4.92271 20.4005 4.46487 20.2109 4.12731 19.8733C3.78974 19.5357 3.6001 19.0779 3.6001 18.6005V10.1357C3.6001 9.6245 3.8161 9.1385 4.1977 8.7965L10.7977 2.8673ZM21.0001 14.4005C21.0001 15.037 20.7472 15.6475 20.2972 16.0976C19.8471 16.5476 19.2366 16.8005 18.6001 16.8005C17.9636 16.8005 17.3531 16.5476 16.903 16.0976C16.453 15.6475 16.2001 15.037 16.2001 14.4005C16.2001 13.764 16.453 13.1535 16.903 12.7034C17.3531 12.2534 17.9636 12.0005 18.6001 12.0005C19.2366 12.0005 19.8471 12.2534 20.2972 12.7034C20.7472 13.1535 21.0001 13.764 21.0001 14.4005ZM22.8001 19.8005C22.8001 21.2945 21.6001 22.8005 18.6001 22.8005C15.6001 22.8005 14.4001 21.3005 14.4001 19.8005C14.4001 19.3231 14.5897 18.8653 14.9273 18.5277C15.2649 18.1901 15.7227 18.0005 16.2001 18.0005H21.0001C21.4775 18.0005 21.9353 18.1901 22.2729 18.5277C22.6105 18.8653 22.8001 19.3231 22.8001 19.8005Z"
        fill={color}
      />
    </svg>
  );
}
