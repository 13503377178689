import { defaultLanguage } from "@/services/constants/global";
import en from "@/services/translations/locales/en.json";
import ko from "@/services/translations/locales/ko.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: defaultLanguage,
  returnEmptyString: false,
  resources: {
    en: {
      translation: en,
    },
    ko: {
      translation: ko,
    },
  },
});

export default i18n;
