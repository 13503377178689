export function IconNotifiedBell({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 20 16" fill="none" height={size} width={size} {...other}>
      <g id="fi_8842478">
        <path
          id="Vector"
          d="M12.7126 8.64464V7.00463C12.7413 6.02288 12.4175 5.0633 11.7997 4.29973C11.1819 3.53615 10.3111 3.01908 9.34503 2.84218C9.51881 2.63956 9.61457 2.38157 9.61508 2.11463C9.57021 0.644905 7.43233 0.638042 7.38508 2.11466C7.3856 2.38156 7.48134 2.63952 7.65508 2.84213C6.68892 3.01896 5.81806 3.53602 5.20031 4.29963C4.58256 5.06325 4.25877 6.02289 4.28764 7.00467L4.28758 8.64213C4.22006 9.52771 3.90183 10.3758 3.37008 11.0871C3.18252 11.3115 3.07986 11.5947 3.08008 11.8871C3.09758 12.2946 3.33758 12.8296 4.37258 13.0871C5.15318 13.3267 5.96163 13.4637 6.77758 13.4946C6.90258 13.5021 7.03508 13.5096 7.17258 13.5146C8.10238 13.5494 9.27308 13.5552 10.2226 13.4921C11.0385 13.463 11.8471 13.3269 12.6276 13.0871C13.7341 12.8569 14.3025 11.977 13.6326 11.0871C13.1016 10.376 12.7827 9.52928 12.7126 8.64464ZM9.09008 2.28963C9.0473 2.43062 8.95539 2.55159 8.83104 2.63059C8.70669 2.70959 8.55813 2.74139 8.41233 2.72022C8.26653 2.69904 8.13316 2.62629 8.03642 2.51518C7.93968 2.40406 7.88598 2.26194 7.88508 2.11461C7.88454 2.02595 7.90314 1.93822 7.93963 1.85742C7.97612 1.77661 8.02963 1.70464 8.0965 1.64642C8.16337 1.5882 8.24203 1.54511 8.32709 1.52009C8.41215 1.49508 8.5016 1.48872 8.58935 1.50147C8.67709 1.51421 8.76104 1.54575 8.83547 1.59393C8.9099 1.64212 8.97304 1.7058 9.02059 1.78064C9.06813 1.85548 9.09895 1.9397 9.11095 2.02754C9.12294 2.11539 9.11582 2.20479 9.09008 2.28963Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M9.72086 14.0202C8.80263 14.0465 7.68789 14.0573 6.75586 13.9927C6.84156 14.3921 7.06168 14.75 7.37945 15.0066C7.69722 15.2632 8.09342 15.4031 8.50187 15.4028C8.91032 15.4025 9.30632 15.2621 9.62372 15.005C9.94112 14.7479 10.1607 14.3897 10.2459 13.9902C10.0777 14.0027 9.90399 14.013 9.72086 14.0202Z"
          fill={color}
        />
        <path
          id=".Ignore"
          d="M9.5 12.375L12.5 15L18.5 8"
          stroke={color}
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
