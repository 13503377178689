export function IconMenuJob({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <path
        d="M10.7044 3.51898C10.034 3.51898 9.46373 3.9848 9.30365 4.61265H14.6863C14.5263 3.9848 13.956 3.51898 13.2856 3.51898H10.7044ZM16.2071 4.61264H18.1881C20.2891 4.61264 22 6.34428 22 8.47085C22 8.47085 21.94 9.3711 21.92 10.6248C21.918 10.724 21.8699 10.8212 21.7909 10.88C21.3097 11.2354 20.8694 11.5291 20.8294 11.5493C19.1686 12.6632 17.2386 13.447 15.1826 13.8369C15.0485 13.8632 14.9165 13.7934 14.8484 13.6739C14.2721 12.6754 13.1956 12.0253 11.995 12.0253C10.8024 12.0253 9.71586 12.6683 9.12256 13.6678C9.05353 13.7853 8.92346 13.8531 8.7904 13.8278C6.75138 13.4369 4.82141 12.6541 3.17059 11.5594L2.21011 10.8911C2.13007 10.8405 2.08004 10.7493 2.08004 10.6481C2.05003 10.1316 2 8.47085 2 8.47085C2 6.34428 3.71086 4.61264 5.81191 4.61264H7.78289C7.97299 3.1443 9.2036 2 10.7044 2H13.2856C14.7864 2 16.017 3.1443 16.2071 4.61264ZM21.6594 12.8153L21.6194 12.8356C19.5984 14.1925 17.1671 15.0938 14.6159 15.4685C14.2557 15.5191 13.8955 15.2862 13.7955 14.9216C13.5753 14.0913 12.865 13.5444 12.0146 13.5444H12.0046H11.9846C11.1341 13.5444 10.4238 14.0913 10.2037 14.9216C10.1036 15.2862 9.74343 15.5191 9.38325 15.4685C6.83198 15.0938 4.40076 14.1925 2.37975 12.8356C2.36974 12.8254 2.26969 12.7647 2.18965 12.8153C2.09961 12.8659 2.09961 12.9875 2.09961 12.9875L2.16964 18.152C2.16964 20.2786 3.87049 22.0001 5.97155 22.0001H18.0176C20.1186 22.0001 21.8195 20.2786 21.8195 18.152L21.8995 12.9875C21.8995 12.9875 21.8995 12.8659 21.8095 12.8153C21.7594 12.7849 21.6994 12.7951 21.6594 12.8153ZM12.7449 17.0583C12.7449 17.4836 12.4148 17.8178 11.9946 17.8178C11.5844 17.8178 11.2442 17.4836 11.2442 17.0583V15.752C11.2442 15.3368 11.5844 14.9925 11.9946 14.9925C12.4148 14.9925 12.7449 15.3368 12.7449 15.752V17.0583Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
