export function IconLocation({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 20 20" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/location">
        <g id="location">
          <path
            id="Vector"
            d="M9.99844 11.8084C8.22344 11.8084 6.77344 10.3667 6.77344 8.58337C6.77344 6.80003 8.22344 5.3667 9.99844 5.3667C11.7734 5.3667 13.2234 6.80837 13.2234 8.5917C13.2234 10.375 11.7734 11.8084 9.99844 11.8084ZM9.99844 6.6167C8.9151 6.6167 8.02344 7.50003 8.02344 8.5917C8.02344 9.68337 8.90677 10.5667 9.99844 10.5667C11.0901 10.5667 11.9734 9.68337 11.9734 8.5917C11.9734 7.50003 11.0818 6.6167 9.99844 6.6167Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M10.0014 18.9667C8.76803 18.9667 7.52637 18.5001 6.5597 17.5751C4.10137 15.2084 1.3847 11.4334 2.4097 6.94175C3.3347 2.86675 6.89303 1.04175 10.0014 1.04175C10.0014 1.04175 10.0014 1.04175 10.0097 1.04175C13.118 1.04175 16.6764 2.86675 17.6014 6.95008C18.618 11.4417 15.9014 15.2084 13.443 17.5751C12.4764 18.5001 11.2347 18.9667 10.0014 18.9667ZM10.0014 2.29175C7.57637 2.29175 4.4597 3.58341 3.6347 7.21675C2.7347 11.1417 5.20137 14.5251 7.4347 16.6667C8.87637 18.0584 11.1347 18.0584 12.5764 16.6667C14.8014 14.5251 17.268 11.1417 16.3847 7.21675C15.5514 3.58341 12.4264 2.29175 10.0014 2.29175Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
