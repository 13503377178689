import { IconQuestionMark } from "@/app/_components/icons";
import { DialogType } from "@/services/constants/global";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const DialogContext = createContext([() => {}, () => {}]);

export const useDialog = () => {
  return useContext(DialogContext);
};

const OkeButtonColor = {
  [DialogType.ALERT]: "red",
  [DialogType.CONFIRM]: "primary",
};

export const DialogContextProvider = ({ children = <></> }) => {
  const { t } = useTranslation();
  const defaultDialogProps = {
    type: DialogType.ALERT,
    title: "",
    description: "",
    confirmText: t("Ok"),
    onConfirm: () => {},
  };
  const [dialog, setDialog] = useState(defaultDialogProps);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openDialog = (dialogProps = defaultDialogProps) => {
    setDialog({ ...defaultDialogProps, ...dialogProps });
    onOpen();
  };

  const closeDialog = () => {
    onClose();
    setDialog(defaultDialogProps);
  };

  const onOkButtonClick = () => {
    onClose();
    dialog.onConfirm();
  };

  return (
    <DialogContext.Provider value={[openDialog, closeDialog]}>
      {children}
      <AlertDialog
        size={{ base: "xs", lg: "md" }}
        isOpen={isOpen}
        onClose={onClose}
        {...dialog}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
            pb={dialog.description ? 2 : 1}
          >
            <IconQuestionMark size={56} color="#F69944" />
            {dialog.title}
          </AlertDialogHeader>
          {dialog.description && (
            <AlertDialogBody>{dialog.description}</AlertDialogBody>
          )}

          <AlertDialogFooter>
            <HStack w="full" justifyContent="center" gap={4}>
              <Button onClick={onClose} variant="outline" colorScheme="gray">
                {t("Cancel")}
              </Button>
              <Button
                onClick={onOkButtonClick}
                colorScheme={OkeButtonColor[dialog.type]}
              >
                {dialog.confirmText}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </DialogContext.Provider>
  );
};
