export function IconOffer({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g clipPath="url(#clip0_6562_40016)">
        <path
          d="M0.285937 8.57496L1.45312 7.71387V11.5412L0 10.8179V9.14074C0 8.91762 0.105937 8.70762 0.285937 8.57496Z"
          fill={color}
        />
        <path
          d="M24 9.14074V10.8179L22.5469 11.5412V7.71387L23.7141 8.57496C23.8941 8.70762 24 8.91762 24 9.14074Z"
          fill={color}
        />
        <path
          d="M24 12.3887V23.297C24 23.6851 23.685 24.0001 23.2969 24.0001H0.703125C0.315 24.0001 0 23.6851 0 23.297V12.3887C0.705141 12.7398 10.9986 17.8652 11.6864 18.2077C11.7853 18.257 11.8927 18.2813 12 18.2813C12.1073 18.2813 12.2147 18.257 12.3136 18.2077C12.9825 17.8747 23.8424 12.4671 24 12.3887Z"
          fill={color}
        />
        <path
          d="M14.1094 1.40625H9.89062L11.5781 0.140625C11.828 -0.046875 12.172 -0.046875 12.4219 0.140625L14.1094 1.40625Z"
          fill={color}
        />
        <path
          d="M20.4375 2.8125H3.5625C3.17437 2.8125 2.85938 3.1275 2.85938 3.51562V12.2414L12 16.793L21.1406 12.2414V3.51562C21.1406 3.1275 20.8256 2.8125 20.4375 2.8125ZM12 11.25H7.78125C7.39313 11.25 7.07812 10.935 7.07812 10.5469C7.07812 10.1588 7.39313 9.84375 7.78125 9.84375H12C12.3881 9.84375 12.7031 10.1588 12.7031 10.5469C12.7031 10.935 12.3881 11.25 12 11.25ZM16.2188 8.4375H7.78125C7.39313 8.4375 7.07812 8.1225 7.07812 7.73438C7.07812 7.34625 7.39313 7.03125 7.78125 7.03125H16.2188C16.6069 7.03125 16.9219 7.34625 16.9219 7.73438C16.9219 8.1225 16.6069 8.4375 16.2188 8.4375Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6562_40016">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
