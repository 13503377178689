export function IconEdit2({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/edit-2">
        <g id="edit-2">
          <path
            id="Vector"
            d="M5.54194 19.52C4.93194 19.52 4.36194 19.31 3.95194 18.92C3.43194 18.43 3.18194 17.69 3.27194 16.89L3.64194 13.65C3.71194 13.04 4.08194 12.23 4.51194 11.79L12.7219 3.09999C14.7719 0.929988 16.9119 0.869988 19.0819 2.91999C21.2519 4.96999 21.3119 7.10999 19.2619 9.27999L11.0519 17.97C10.6319 18.42 9.85194 18.84 9.24194 18.94L6.02194 19.49C5.85194 19.5 5.70194 19.52 5.54194 19.52ZM15.9319 2.90999C15.1619 2.90999 14.4919 3.38999 13.8119 4.10999L5.60194 12.81C5.40194 13.02 5.17194 13.52 5.13194 13.81L4.76194 17.05C4.72194 17.38 4.80194 17.65 4.98194 17.82C5.16194 17.99 5.43194 18.05 5.76194 18L8.98194 17.45C9.27194 17.4 9.75194 17.14 9.95194 16.93L18.1619 8.23999C19.4019 6.91999 19.8519 5.69999 18.0419 3.99999C17.2419 3.22999 16.5519 2.90999 15.9319 2.90999Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M17.3404 10.95C17.3204 10.95 17.2904 10.95 17.2704 10.95C14.1504 10.64 11.6404 8.26997 11.1604 5.16997C11.1004 4.75997 11.3804 4.37997 11.7904 4.30997C12.2004 4.24997 12.5804 4.52997 12.6504 4.93997C13.0304 7.35997 14.9904 9.21997 17.4304 9.45997C17.8404 9.49997 18.1404 9.86997 18.1004 10.28C18.0504 10.66 17.7204 10.95 17.3404 10.95Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
