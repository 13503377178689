export function IconBriefCase({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <path
        d="M21.0891 6.98002C20.2391 6.04002 18.8191 5.57002 16.7591 5.57002H16.5191V5.53002C16.5191 3.85002 16.5191 1.77002 12.7591 1.77002H11.2391C7.47906 1.77002 7.47906 3.86002 7.47906 5.53002V5.58002H7.23906C5.16906 5.58002 3.75906 6.05002 2.90906 6.99002C1.91906 8.09002 1.94906 9.57002 2.04906 10.58L2.05906 10.65L2.13652 11.4633C2.15078 11.6131 2.23144 11.7484 2.35734 11.8307C2.59714 11.9877 2.99849 12.2464 3.23906 12.38C3.37906 12.47 3.52906 12.55 3.67906 12.63C5.38906 13.57 7.26906 14.2 9.17906 14.51C9.26906 15.45 9.67906 16.55 11.8691 16.55C14.0591 16.55 14.4891 15.46 14.5591 14.49C16.5991 14.16 18.5691 13.45 20.3491 12.41C20.4091 12.38 20.4491 12.35 20.4991 12.32C20.8958 12.0958 21.3074 11.8195 21.6825 11.5489C21.7955 11.4673 21.8678 11.3413 21.8832 11.2028L21.8991 11.06L21.9491 10.59C21.9591 10.53 21.9591 10.48 21.9691 10.41C22.0491 9.40002 22.0291 8.02002 21.0891 6.98002ZM13.0891 13.83C13.0891 14.89 13.0891 15.05 11.8591 15.05C10.6291 15.05 10.6291 14.86 10.6291 13.84V12.58H13.0891V13.83ZM8.90906 5.57002V5.53002C8.90906 3.83002 8.90906 3.20002 11.2391 3.20002H12.7591C15.0891 3.20002 15.0891 3.84002 15.0891 5.53002V5.58002H8.90906V5.57002Z"
        fill={color}
      />
      <path
        d="M20.8733 13.7342C21.2269 13.5659 21.6342 13.8462 21.5988 14.2362L21.2398 18.19C21.0298 20.19 20.2098 22.23 15.8098 22.23H8.18984C3.78984 22.23 2.96984 20.19 2.75984 18.2L2.41913 14.4522C2.38409 14.0667 2.78205 13.7868 3.13468 13.9463C4.2741 14.4618 6.37724 15.3764 7.67641 15.7167C7.84072 15.7597 7.97361 15.8773 8.04556 16.0312C8.65253 17.3293 9.96896 18.02 11.8698 18.02C13.752 18.02 15.085 17.3027 15.694 16.0014C15.766 15.8474 15.8991 15.7299 16.0635 15.6866C17.443 15.3236 19.6816 14.3013 20.8733 13.7342Z"
        fill={color}
      />
    </svg>
  );
}
