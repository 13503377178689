export function IconExportOutline({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 14 14" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/export">
        <g id="export">
          <path
            id="Vector"
            d="M7.58448 6.85414C7.47365 6.85414 7.36281 6.81331 7.27531 6.72581C7.10615 6.55664 7.10615 6.27664 7.27531 6.10747L12.0586 1.32414C12.2278 1.15497 12.5078 1.15497 12.677 1.32414C12.8461 1.49331 12.8461 1.77331 12.677 1.94247L7.89365 6.72581C7.80615 6.81331 7.69531 6.85414 7.58448 6.85414Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M12.8312 4.404C12.5921 4.404 12.3937 4.20567 12.3937 3.9665V1.604H10.0312C9.79208 1.604 9.59375 1.40567 9.59375 1.1665C9.59375 0.927337 9.79208 0.729004 10.0312 0.729004H12.8312C13.0704 0.729004 13.2687 0.927337 13.2687 1.1665V3.9665C13.2687 4.20567 13.0704 4.404 12.8312 4.404Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M8.7474 13.2707H5.2474C2.0799 13.2707 0.726562 11.9173 0.726562 8.74984V5.24984C0.726562 2.08234 2.0799 0.729004 5.2474 0.729004H6.41406C6.65323 0.729004 6.85156 0.927337 6.85156 1.1665C6.85156 1.40567 6.65323 1.604 6.41406 1.604H5.2474C2.55823 1.604 1.60156 2.56067 1.60156 5.24984V8.74984C1.60156 11.439 2.55823 12.3957 5.2474 12.3957H8.7474C11.4366 12.3957 12.3932 11.439 12.3932 8.74984V7.58317C12.3932 7.344 12.5916 7.14567 12.8307 7.14567C13.0699 7.14567 13.2682 7.344 13.2682 7.58317V8.74984C13.2682 11.9173 11.9149 13.2707 8.7474 13.2707Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
