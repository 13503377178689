export function IconCall({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 25 24" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/bold/call">
        <g id="call">
          <path
            id="Vector"
            d="M11.55 14.95L9.7 16.8C9.31 17.19 8.69 17.19 8.29 16.81C8.18 16.7 8.07 16.6 7.96 16.49C6.93 15.45 6 14.36 5.17 13.22C4.35 12.08 3.69 10.94 3.21 9.81C2.74 8.67 2.5 7.58 2.5 6.54C2.5 5.86 2.62 5.21 2.86 4.61C3.1 4 3.48 3.44 4.01 2.94C4.65 2.31 5.35 2 6.09 2C6.37 2 6.65 2.06 6.9 2.18C7.16 2.3 7.39 2.48 7.57 2.74L9.89 6.01C10.07 6.26 10.2 6.49 10.29 6.71C10.38 6.92 10.43 7.13 10.43 7.32C10.43 7.56 10.36 7.8 10.22 8.03C10.09 8.26 9.9 8.5 9.66 8.74L8.9 9.53C8.79 9.64 8.74 9.77 8.74 9.93C8.74 10.01 8.75 10.08 8.77 10.16C8.8 10.24 8.83 10.3 8.85 10.36C9.03 10.69 9.34 11.12 9.78 11.64C10.23 12.16 10.71 12.69 11.23 13.22C11.33 13.32 11.44 13.42 11.54 13.52C11.94 13.91 11.95 14.55 11.55 14.95Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M22.4696 18.33C22.4696 18.61 22.4196 18.9 22.3196 19.18C22.2896 19.26 22.2596 19.34 22.2196 19.42C22.0496 19.78 21.8296 20.12 21.5396 20.44C21.0496 20.98 20.5096 21.37 19.8996 21.62C19.8896 21.62 19.8796 21.63 19.8696 21.63C19.2796 21.87 18.6396 22 17.9496 22C16.9296 22 15.8396 21.76 14.6896 21.27C13.5396 20.78 12.3896 20.12 11.2496 19.29C10.8596 19 10.4696 18.71 10.0996 18.4L13.3696 15.13C13.6496 15.34 13.8996 15.5 14.1096 15.61C14.1596 15.63 14.2196 15.66 14.2896 15.69C14.3696 15.72 14.4496 15.73 14.5396 15.73C14.7096 15.73 14.8396 15.67 14.9496 15.56L15.7096 14.81C15.9596 14.56 16.1996 14.37 16.4296 14.25C16.6596 14.11 16.8896 14.04 17.1396 14.04C17.3296 14.04 17.5296 14.08 17.7496 14.17C17.9696 14.26 18.1996 14.39 18.4496 14.56L21.7596 16.91C22.0196 17.09 22.1996 17.3 22.3096 17.55C22.4096 17.8 22.4696 18.05 22.4696 18.33Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
