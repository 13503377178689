export function IconRequestAgain({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <circle cx="12" cy="12" r="12" fill={color} />
      <g clipPath="url(#clip0_3463_337922)">
        <path
          d="M11.2358 19.9551C8.02618 19.5956 5.45393 17.0299 5.08787 13.8203C4.60741 9.62366 7.86929 6.05128 11.9581 6.00552V4.12291C11.9581 4.01832 12.0888 3.96276 12.1771 4.02813L16.0534 6.87492C16.1188 6.92395 16.1188 7.01873 16.0534 7.06776L12.1771 9.91455C12.0888 9.97992 11.9581 9.92109 11.9581 9.81977V7.94043C9.08515 7.98618 6.78418 10.431 6.98028 13.3529C7.14697 15.8663 9.19627 17.9058 11.7097 18.066C14.3735 18.236 16.6385 16.3337 17.0438 13.817C17.1189 13.3497 17.5275 13.0097 17.9981 13.0097C18.5832 13.0097 19.0408 13.5294 18.9492 14.1079C18.3805 17.6934 15.0696 20.3833 11.2358 19.9551Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3463_337922">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-1 0 0 1 20 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
