export function IconTimer({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 16 16" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/timer">
        <g id="timer">
          <path
            id="Vector"
            d="M10.1592 15.1663H5.83923C4.57923 15.1663 3.65923 14.6397 3.3059 13.733C2.93923 12.7863 3.27923 11.613 4.15923 10.8197L7.25256 7.99967L4.15923 5.17967C3.27923 4.38634 2.93923 3.21301 3.3059 2.26634C3.65923 1.35301 4.57923 0.833008 5.83923 0.833008H10.1592C11.4192 0.833008 12.3392 1.35967 12.6926 2.26634C13.0592 3.21301 12.7192 4.38634 11.8392 5.17967L8.7459 7.99967L11.8459 10.8197C12.7192 11.613 13.0659 12.7863 12.6992 13.733C12.3392 14.6397 11.4192 15.1663 10.1592 15.1663ZM7.99923 8.67301L4.83256 11.553C4.27256 12.0663 4.0259 12.813 4.23923 13.3663C4.43923 13.8797 5.0059 14.1663 5.83923 14.1663H10.1592C10.9926 14.1663 11.5592 13.8863 11.7592 13.3663C11.9726 12.813 11.7326 12.0663 11.1659 11.553L7.99923 8.67301ZM5.83923 1.83301C5.0059 1.83301 4.43923 2.11301 4.23923 2.63301C4.0259 3.18634 4.2659 3.93301 4.83256 4.44634L7.99923 7.32634L11.1659 4.44634C11.7259 3.93301 11.9726 3.18634 11.7592 2.63301C11.5592 2.11967 10.9926 1.83301 10.1592 1.83301H5.83923Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
