export function IconSetting({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 16 16" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/bold/setting-4">
        <g id="setting-4">
          <path
            id="Vector"
            d="M14.1984 5.05345H10.4784C10.2184 5.05345 10.0117 4.84678 10.0117 4.58678C10.0117 4.32678 10.2184 4.12012 10.4784 4.12012H14.1984C14.4584 4.12012 14.6651 4.32678 14.6651 4.58678C14.6651 4.84678 14.4584 5.05345 14.1984 5.05345Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M4.2787 5.05345H1.7987C1.5387 5.05345 1.33203 4.84678 1.33203 4.58678C1.33203 4.32678 1.5387 4.12012 1.7987 4.12012H4.2787C4.5387 4.12012 4.74536 4.32678 4.74536 4.58678C4.74536 4.84678 4.53203 5.05345 4.2787 5.05345Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M6.75833 7.21979C8.21268 7.21979 9.39167 6.04081 9.39167 4.58646C9.39167 3.13211 8.21268 1.95312 6.75833 1.95312C5.30398 1.95312 4.125 3.13211 4.125 4.58646C4.125 6.04081 5.30398 7.21979 6.75833 7.21979Z"
            fill={color}
          />
          <path
            id="Vector_4"
            d="M14.2006 11.8738H11.7206C11.4606 11.8738 11.2539 11.6671 11.2539 11.4071C11.2539 11.1471 11.4606 10.9404 11.7206 10.9404H14.2006C14.4606 10.9404 14.6672 11.1471 14.6672 11.4071C14.6672 11.6671 14.4606 11.8738 14.2006 11.8738Z"
            fill={color}
          />
          <path
            id="Vector_5"
            d="M5.5187 11.8738H1.7987C1.5387 11.8738 1.33203 11.6671 1.33203 11.4071C1.33203 11.1471 1.5387 10.9404 1.7987 10.9404H5.5187C5.7787 10.9404 5.98536 11.1471 5.98536 11.4071C5.98536 11.6671 5.77203 11.8738 5.5187 11.8738Z"
            fill={color}
          />
          <path
            id="Vector_6"
            d="M9.2388 14.0469C10.6932 14.0469 11.8721 12.868 11.8721 11.4136C11.8721 9.95926 10.6932 8.78027 9.2388 8.78027C7.78445 8.78027 6.60547 9.95926 6.60547 11.4136C6.60547 12.868 7.78445 14.0469 9.2388 14.0469Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
