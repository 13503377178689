export function IconResetFilter({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 25" fill="none" height={size} width={size} {...other}>
      <g mask="url(#mask0_1517_85430)">
        <path
          d="M11 20.1008C9.35005 19.8674 7.98755 19.1258 6.91255 17.8758C5.83755 16.6258 5.30005 15.1674 5.30005 13.5008C5.30005 12.6174 5.47505 11.7716 5.82505 10.9633C6.17505 10.1549 6.65005 9.43411 7.25005 8.80078L7.75005 9.30078C7.16672 9.85078 6.72922 10.4883 6.43755 11.2133C6.14588 11.9383 6.00005 12.7008 6.00005 13.5008C6.00005 14.9674 6.47088 16.2549 7.41255 17.3633C8.35422 18.4716 9.55005 19.1508 11 19.4008V20.1008ZM13 20.1508V19.4508C14.4334 19.1174 15.625 18.4049 16.575 17.3133C17.525 16.2216 18 14.9508 18 13.5008C18 11.8341 17.4167 10.4174 16.25 9.25078C15.0834 8.08411 13.6667 7.50078 12 7.50078H11.2L12.95 9.25078L12.45 9.75078L9.85005 7.15078L12.45 4.55078L12.95 5.05078L11.2 6.80078H12C13.8667 6.80078 15.45 7.45078 16.75 8.75078C18.05 10.0508 18.7 11.6341 18.7 13.5008C18.7 15.1674 18.1584 16.6174 17.075 17.8508C15.9917 19.0841 14.6334 19.8508 13 20.1508Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
