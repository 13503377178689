export function IconQuestionMark({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 57 57" fill="none" height={size} width={size} {...other}>
      <g id="fi_471715" clipPath="url(#clip0_1601_334062)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M28.5 0.5C13.0244 0.5 0.5 13.023 0.5 28.5C0.5 43.9754 13.023 56.5 28.5 56.5C43.9756 56.5 56.5 43.977 56.5 28.5C56.5 13.0244 43.977 0.5 28.5 0.5ZM27.6715 41.6052C26.0866 41.6052 24.8704 40.2784 24.8704 38.7671C24.8704 37.2192 26.1235 35.9293 27.6715 35.9293C29.2196 35.9293 30.5093 37.2193 30.5093 38.7673C30.5093 40.2783 29.2562 41.6052 27.6715 41.6052ZM31.873 27.268C29.846 28.8528 29.809 29.9585 29.809 31.875C29.809 32.5754 29.4404 33.3862 27.6345 33.3862C26.1233 33.3862 25.6075 32.8333 25.6075 30.9169C25.6075 27.7472 27.008 26.236 28.0768 25.3147C29.2931 24.2826 31.3571 23.1402 31.3571 21.15C31.3571 19.4545 29.8828 18.6437 28.04 18.6437C24.2806 18.6437 25.0915 21.4817 23.1011 21.4817C22.106 21.4817 20.8898 20.8182 20.8898 19.3809C20.8898 17.3907 23.1749 14.442 28.1505 14.442C32.8681 14.442 36.0009 17.0589 36.0009 20.5234C36.0009 23.9878 32.8681 26.4941 31.873 27.268Z"
              fill={color}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1601_334062">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
