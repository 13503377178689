export function IconZoomLogo2({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" height={size} width={size} {...other}>
      <g clipPath="url(#clip0_3457_337898)">
        <g>
          <path
            id="Vector"
            d="M12 0C18.6277 0 24 5.37234 24 12C24 18.6277 18.6277 24 12 24C5.37234 24 0 18.6277 0 12C0 5.37234 5.37234 0 12 0ZM6.15469 9.14766V13.4288C6.15844 14.3972 6.94922 15.1763 7.91344 15.172H14.1544C14.3316 15.172 14.4741 15.0295 14.4741 14.8561V10.5745C14.4703 9.60656 13.6795 8.8275 12.7153 8.83125H6.47484C6.29719 8.83125 6.15469 8.97422 6.15469 9.14766ZM14.8716 10.8178L17.4483 8.93531C17.6719 8.75016 17.8453 8.79656 17.8453 9.13219V14.8716C17.8453 15.2531 17.6334 15.2072 17.4483 15.068L14.8716 13.1897V10.8178Z"
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3457_337898">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
