// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    primary: { 500: "#232F57" },
    secondary: "#3C64B1",
    banner: "#294BA0",
    main: "#F6F6F6",
    sub: "#F8F8F8",
    faded: "#F5F7FA",
    placeholder: "#6C6B6B",
    tag: "#6C8AC6",
    canceled: { bg: "#ED1C1C", color: "#FFFFFF" },
    confirmed: { bg: "#2D28FF", color: "#FFFFFF" },
    passed: { bg: "#38A169", color: "#FFFFFF" },
    apply: { bg: "#A700E2", color: "#FFFFFF" },
    applied: { bg: "#A700E2", color: "#FFFFFF" },
    accepted: { bg: "#A700E2", color: "#FFFFFF" },
    completed: { bg: "#38A169", color: "#FFFFFF" },
    failed: { bg: "#ED1C1C", color: "#FFFFFF" },
    hold: { bg: "#F6C244", color: "#FFFFFF" },
    requestChanged: { bg: "#F6C244", color: "#FFFFFF" },
    menu: "#494949",
    gray: {
      800: "#0A0909",
    },
    blue: {
      500: "#6C8AC6",
    },
    background: "#F4F3F3",
  },
  fontSizes: {
    lg: "0.875rem",
  },
  fonts: {
    body: "'Gilroy', sans-serif",
    heading: "'Gilroy', sans-serif",
  },
  sizes: {
    container: {
      "2xl": "1580px",
      xl: "1232px",
    },
  },
  components: {
    Text: {
      baseStyle: {
        fontSize: "md",
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "semibold",
      },
      // 3. We can add a new visual variant
      variants: {
        solid: {},
        thin: {
          color: "white",
          bgColor: "primary.500",
          fontSize: "12px",
          h: "fit-content",
          w: "full",
          py: "3px",
          rounded: "lg",
          lineHeight: "18px",
          fontWeight: "normal",
          _disabled: {
            color: "#6C6B6B",
            bgColor: "#ECECEC",
          },
          _hover: {
            _disabled: {
              bgColor: "#ECECEC",
              background: "#ECECEC",
            },
          },
        },
        thinOutline: {
          color: "primary.500",
          bgColor: "white",
          border: "1px solid",
          borderColor: "primary.500",
          fontSize: "12px",
          h: "fit-content",
          w: "full",
          py: "2px",
          rounded: "lg",
          lineHeight: "18px",
          fontWeight: "normal",
          _disabled: {
            color: "#6C6B6B",
            bgColor: "#ECECEC",
            borderColor: "#6C6B6B",
          },
          _hover: {
            _disabled: {
              bgColor: "#ECECEC",
              background: "#ECECEC",
            },
          },
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        colorScheme: "primary",
        paddingInline: 4,
      },
    },
    Tag: {
      defaultProps: {
        colorScheme: "blue",
      },
    },
    Card: {
      baseStyle: {
        container: {
          rounded: "md",
        },
      },
      defaultProps: {
        overflow: "hidden",
        variant: "outline",
      },
    },
    Checkbox: {
      sizes: {
        lg: {
          label: {
            fontSize: 14,
          },
        },
      },
      defaultProps: {
        size: "lg",
        colorScheme: "primary",
      },
      variants: {
        circular: {
          control: {
            rounded: "full",
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            // borderColor: "primary",
            _placeholder: {
              color: "placeholder",
            },
          },
          element: {
            color: "primary",
          },
        },
      },
      defaultProps: {
        variant: "outline",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "primary",
      },
      variants: {
        filter: {
          control: {
            border: "none",
            _checked: {
              outline: "none",
              border: "none",
              color: "secondary",
              background: "none",
              boxShadow: "none",
              _focusVisible: {
                boxShadow: "none",
              },
              _before: {
                borderRadius: 0,
                background: "none",
                border: "none",
              },
              _after: {
                content: '"✔"',
                color: "secondary",
              },
            },
          },
          container: {
            w: "full",
            justifyContent: "space-between",
            flexDirection: "row-reverse",
            gap: 4,
            whiteSpace: "nowrap",
          },
          label: {
            marginInlineStart: 0,
            _checked: {
              color: "secondary",
            },
          },
        },
      },
    },
    Tabs: {
      variants: {
        enclosed: {
          tablist: {
            alignItems: "end",
            gap: "px",
          },
          tab: {
            h: "36px",
            bgColor: "faded",
            fontSize: 16,
            fontWeight: 600,
            _selected: {
              h: "48px",
              color: "secondary",
              bgColor: "white",
              roundedTop: "xl",
            },
          },
          tabpanels: {
            borderWidth: 1,
            bg: "white",
            roundedBottom: "xl",
          },
        },
      },
      defaultProps: {},
    },
    Table: {
      variants: {
        theadShadow: {
          thead: {
            tr: {
              th: {
                h: 54,
                color: "#000",
                textTransform: "none",
                fontWeight: "normal",
              },
              boxShadow: "0px 4px 4px rgba(210, 208, 208, 0.25)",
              border: "none",
            },
          },
          tbody: {
            tr: {
              td: {
                fontSize: "12px",
                p: {
                  fontSize: "12px",
                },
              },
              borderBottom: "1px solid",
              borderColor: "#E8E8E8",
            },
          },
        },
      },
    },
    Select: {},
    Progress: {
      variants: {
        orange: {
          filledTrack: {
            bg: "#EB9476",
          },
          bgColor: "rgba(235, 148, 118, 0.1)",
        },
        purple: {
          filledTrack: {
            bg: "#926FD2",
          },
          bgColor: "rgba(146, 111, 210, 0.1)",
        },
        pink: {
          filledTrack: {
            bg: "#E67BB8",
          },
          bgColor: "rgba(230, 123, 184, 0.1)",
        },
        blue: {
          filledTrack: {
            bg: "#5387F0",
          },
          bgColor: "rgba(83, 135, 240, 0.1)",
        },
      },
    },
  },
});

export default theme;
