export function IconIntroVideo({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <path
        d="M5.3646 3.1457L9.07603 6.85713H2.64746C2.77864 6.06913 3.09279 5.32287 3.56468 4.6783C4.03657 4.03373 4.65305 3.50881 5.3646 3.1457ZM10.3532 2.57141H7.71317C7.55031 2.57141 7.38746 2.57998 7.2246 2.58855L11.4932 6.85713H14.6389L10.3532 2.57141ZM16.2846 2.57141H12.7875L17.0732 6.85713H21.3503C21.1495 5.6589 20.5303 4.57071 19.6028 3.786C18.6753 3.0013 17.4995 2.57093 16.2846 2.57141ZM10.6977 16.8223L14.9834 14.4651C15.1179 14.3913 15.2302 14.2826 15.3084 14.1505C15.3866 14.0185 15.4278 13.8678 15.4278 13.7143C15.4278 13.5608 15.3866 13.4101 15.3084 13.278C15.2302 13.1459 15.1179 13.0373 14.9834 12.9634L10.6977 10.6063C10.5672 10.5345 10.4202 10.498 10.2713 10.5003C10.1224 10.5026 9.97663 10.5437 9.84842 10.6195C9.72021 10.6952 9.61397 10.8031 9.54015 10.9325C9.46633 11.0618 9.42749 11.2082 9.42746 11.3571V16.0714C9.42749 16.2203 9.46633 16.3667 9.54015 16.4961C9.61397 16.6254 9.72021 16.7333 9.84842 16.8091C9.97663 16.8849 10.1224 16.9259 10.2713 16.9283C10.4202 16.9306 10.5672 16.894 10.6977 16.8223ZM21.4275 8.57141V16.2857C21.4264 17.6493 20.8842 18.9568 19.9199 19.921C18.9557 20.8853 17.6482 21.4275 16.2846 21.4286H7.71317C6.34953 21.4275 5.04206 20.8853 4.07782 19.921C3.11359 18.9568 2.5714 17.6493 2.57031 16.2857V8.57141H21.4275Z"
        fill={color}
      />
    </svg>
  );
}
