export function IconFilter({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 25" fill="none" height={size} width={size} {...other}>
      <g mask="url(#mask0_1517_85439)">
        <path
          d="M11.6499 20.8504V16.1504H12.3499V18.1504H20.3499V18.8504H12.3499V20.8504H11.6499ZM3.6499 18.8504V18.1504H8.3499V18.8504H3.6499ZM7.6499 14.8504V12.8504H3.6499V12.1504H7.6499V10.1504H8.3499V14.8504H7.6499ZM11.6499 12.8504V12.1504H20.3499V12.8504H11.6499ZM15.6499 8.85039V4.15039H16.3499V6.15039H20.3499V6.85039H16.3499V8.85039H15.6499ZM3.6499 6.85039V6.15039H12.3499V6.85039H3.6499Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
