export function IconTrash({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/trash">
        <g id="trash">
          <path
            id="Vector"
            d="M20.9997 6.72998C20.9797 6.72998 20.9497 6.72998 20.9197 6.72998C15.6297 6.19998 10.3497 5.99998 5.11967 6.52998L3.07967 6.72998C2.65967 6.76998 2.28967 6.46998 2.24967 6.04998C2.20967 5.62998 2.50967 5.26998 2.91967 5.22998L4.95967 5.02998C10.2797 4.48998 15.6697 4.69998 21.0697 5.22998C21.4797 5.26998 21.7797 5.63998 21.7397 6.04998C21.7097 6.43998 21.3797 6.72998 20.9997 6.72998Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M8.50074 5.72C8.46074 5.72 8.42074 5.72 8.37074 5.71C7.97074 5.64 7.69074 5.25 7.76074 4.85L7.98074 3.54C8.14074 2.58 8.36074 1.25 10.6907 1.25H13.3107C15.6507 1.25 15.8707 2.63 16.0207 3.55L16.2407 4.85C16.3107 5.26 16.0307 5.65 15.6307 5.71C15.2207 5.78 14.8307 5.5 14.7707 5.1L14.5507 3.8C14.4107 2.93 14.3807 2.76 13.3207 2.76H10.7007C9.64074 2.76 9.62074 2.9 9.47074 3.79L9.24074 5.09C9.18074 5.46 8.86074 5.72 8.50074 5.72Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M15.2104 22.75H8.79039C5.30039 22.75 5.16039 20.82 5.05039 19.26L4.40039 9.19001C4.37039 8.78001 4.69039 8.42001 5.10039 8.39001C5.52039 8.37001 5.87039 8.68001 5.90039 9.09001L6.55039 19.16C6.66039 20.68 6.70039 21.25 8.79039 21.25H15.2104C17.3104 21.25 17.3504 20.68 17.4504 19.16L18.1004 9.09001C18.1304 8.68001 18.4904 8.37001 18.9004 8.39001C19.3104 8.42001 19.6304 8.77001 19.6004 9.19001L18.9504 19.26C18.8404 20.82 18.7004 22.75 15.2104 22.75Z"
            fill={color}
          />
          <path
            id="Vector_4"
            d="M13.6581 17.25H10.3281C9.91813 17.25 9.57812 16.91 9.57812 16.5C9.57812 16.09 9.91813 15.75 10.3281 15.75H13.6581C14.0681 15.75 14.4081 16.09 14.4081 16.5C14.4081 16.91 14.0681 17.25 13.6581 17.25Z"
            fill={color}
          />
          <path
            id="Vector_5"
            d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
