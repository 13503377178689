export function IconResume({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <path
        d="M21.0246 3.45598C21.0246 1.82398 19.6806 0.47998 18.0486 0.47998H10.3686C9.88856 0.47998 9.50456 0.57598 9.21656 0.76798C8.92856 0.863981 8.54456 1.05598 8.35256 1.24798L3.93656 5.56798C3.64856 5.75998 3.45656 6.04798 3.26456 6.43198C3.07256 6.81598 2.97656 7.19998 2.97656 7.67998V20.448C2.97656 22.08 4.32056 23.424 5.95256 23.424H18.0486C19.6806 23.424 21.0246 22.08 21.0246 20.448V3.45598ZM12.0006 6.91198C13.4406 6.91198 14.5926 8.06398 14.5926 9.50398C14.5926 10.944 13.4406 12.096 12.0006 12.096C10.5606 12.096 9.40856 10.944 9.40856 9.50398C9.40856 8.06398 10.5606 6.91198 12.0006 6.91198ZM15.4566 18.24H9.21656C7.58456 18.24 7.00856 17.472 7.39256 16.512C7.87256 15.264 8.83256 14.304 9.98456 13.824C10.1766 13.728 10.3686 13.728 10.5606 13.824C11.5206 14.208 12.6726 14.112 13.5366 13.824C13.7286 13.728 13.9206 13.728 14.1126 13.824C15.2646 14.4 16.2246 15.36 16.7046 16.608C16.9926 17.376 16.3206 18.24 15.4566 18.24Z"
        fill={color}
      />
    </svg>
  );
}
