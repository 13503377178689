export function IconDownload({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 12 12" fill="none" height={size} width={size} {...other}>
      <g id=".left-icon">
        <path
          id="Vector"
          d="M2.5 10H9.5V9H2.5V10ZM9.5 4.5H7.5V1.5H4.5V4.5H2.5L6 8L9.5 4.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
