export function IconMenuAvatar({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <path
        d="M12 6C11.2583 6 10.5333 6.21993 9.91661 6.63199C9.29993 7.04404 8.81928 7.62971 8.53545 8.31494C8.25162 9.00016 8.17736 9.75416 8.32206 10.4816C8.46675 11.209 8.8239 11.8772 9.34835 12.4017C9.8728 12.9261 10.541 13.2833 11.2684 13.4279C11.9958 13.5726 12.7498 13.4984 13.4351 13.2145C14.1203 12.9307 14.706 12.4501 15.118 11.8334C15.5301 11.2167 15.75 10.4917 15.75 9.75C15.75 8.75544 15.3549 7.80161 14.6517 7.09835C13.9484 6.39509 12.9946 6 12 6Z"
        fill={color}
      />
      <path
        d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.4968 9.2162 21.3896 6.54733 19.4211 4.57889C17.4527 2.61044 14.7838 1.50318 12 1.5ZM17.994 18.6945C17.9791 17.7107 17.5782 16.7722 16.8779 16.0812C16.1776 15.3901 15.2339 15.0019 14.25 15H9.75C8.76614 15.0019 7.82238 15.3901 7.12207 16.0812C6.42176 16.7722 6.02095 17.7107 6.006 18.6945C4.64593 17.4801 3.6868 15.8812 3.25563 14.1095C2.82446 12.3378 2.94158 10.477 3.59148 8.77339C4.24139 7.06978 5.39342 5.60375 6.89503 4.56942C8.39665 3.5351 10.177 2.98127 12.0004 2.98127C13.8237 2.98127 15.6041 3.5351 17.1057 4.56942C18.6073 5.60375 19.7594 7.06978 20.4093 8.77339C21.0592 10.477 21.1763 12.3378 20.7451 14.1095C20.314 15.8812 19.3541 17.4801 17.994 18.6945Z"
        fill={color}
      />
    </svg>
  );
}
