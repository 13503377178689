export function IconComplete({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="Group 2087324238">
        <circle id="Ellipse 19057" cx="12" cy="12" r="12" fill={color} />
        <path
          id="Vector"
          d="M10.2951 16C10.055 16 9.82691 15.9107 9.65883 15.7544L6.26113 12.5957C5.91296 12.2721 5.91296 11.7363 6.26113 11.4126C6.6093 11.0889 7.18559 11.0889 7.53377 11.4126L10.2951 13.9798L16.4662 8.24276C16.8144 7.91908 17.3907 7.91908 17.7389 8.24276C18.087 8.56645 18.087 9.1022 17.7389 9.42588L10.9315 15.7544C10.7634 15.9107 10.5353 16 10.2951 16Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
