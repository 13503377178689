export function IconVideoInterviewApplicant({
  size = 24,
  color = "currentColor",
  ...other
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="fi_5795481">
        <g id="Layer_2">
          <g id="Group">
            <path
              id="Vector"
              d="M22.0951 11.0399V15.0674C22.0951 17.2874 20.3176 19.0949 18.1126 19.1624V22.4999L14.7751 19.1624H10.6276C9.23258 19.1624 8.00258 18.4649 7.26758 17.3999L9.44258 15.2249H12.9601C16.0501 15.2249 18.5626 12.7124 18.5626 9.62992V6.98242C20.5576 7.25242 22.0951 8.96992 22.0951 11.0399Z"
              fill={color}
            />
          </g>
          <g id="Group_2">
            <path
              id="Vector_2"
              d="M12.96 1.5H5.6025C3.3375 1.5 1.5 3.3375 1.5 5.6025V9.63C1.5 11.85 3.27 13.6575 5.4825 13.725V17.0625L8.82 13.725H12.96C15.225 13.725 17.0625 11.895 17.0625 9.63V5.6025C17.0625 3.3375 15.225 1.5 12.96 1.5ZM14.295 9.1725L12.0825 8.6325V8.82C12.0825 9.435 11.58 9.945 10.9575 9.945H5.3925C4.77 9.945 4.2675 9.435 4.2675 8.82V5.8725C4.2675 5.25 4.77 4.7475 5.3925 4.7475H10.9575C11.58 4.7475 12.0825 5.25 12.0825 5.8725V6.0525L14.295 5.5125V9.1725Z"
              fill={color}
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_3"
              d="M6.07362 7.34354C6.48535 7.34354 6.81912 7.00977 6.81912 6.59804C6.81912 6.18631 6.48535 5.85254 6.07362 5.85254C5.6619 5.85254 5.32812 6.18631 5.32812 6.59804C5.32812 7.00977 5.6619 7.34354 6.07362 7.34354Z"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
