export function IconCancel({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="fi_660252" clipPath="url(#clip0_3463_337937)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M20.4891 3.51091C15.8069 -1.1703 8.19233 -1.1703 3.51013 3.51091C-1.17004 8.19212 -1.17004 15.8087 3.51013 20.4899C5.85123 22.83 8.92566 23.9995 12.0001 23.9995C15.0746 23.9995 18.148 22.8299 20.4891 20.4899C25.1703 15.8087 25.1703 8.19212 20.4891 3.51091ZM16.9514 15.5375C17.3426 15.9287 17.3426 16.561 16.9514 16.9522C16.7563 17.1473 16.5002 17.2453 16.2441 17.2453C15.988 17.2453 15.7318 17.1473 15.5367 16.9522L12.0001 13.4145L8.46442 16.9512C8.26835 17.1463 8.0122 17.2443 7.75708 17.2443C7.50097 17.2443 7.24482 17.1463 7.04973 16.9512C6.65856 16.56 6.65856 15.9267 7.04973 15.5365L10.5854 11.9999L7.04874 8.46323C6.65758 8.07206 6.65758 7.43876 7.04874 7.04858C7.43893 6.65741 8.07222 6.65741 8.46339 7.04858L12 10.5852L15.5367 7.04858C15.9278 6.65741 16.5602 6.65741 16.9513 7.04858C17.3425 7.43876 17.3425 8.07206 16.9513 8.46323L13.4147 11.9999L16.9514 15.5375Z"
              fill={color}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3463_337937">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
