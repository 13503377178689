export function IconGender({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 20 20" fill="none" height={size} width={size} {...other}>
      <g id="fi_2619883" clipPath="url(#clip0_2195_309083)">
        <g id="Group">
          <path
            id="Vector"
            d="M19.4141 0H15.8984C15.5748 0 15.3125 0.262344 15.3125 0.585938C15.3125 0.909531 15.5748 1.17188 15.8984 1.17188H17.9995L14.4941 4.67727C13.5836 3.95098 12.4281 3.51562 11.1719 3.51562C10.0886 3.51562 9.0807 3.8441 8.24219 4.40648C7.40367 3.8441 6.39578 3.51562 5.3125 3.51562C2.3832 3.51562 0 5.88129 0 8.78906C0 11.5003 2.07195 13.7398 4.72656 14.0303V16.4844H2.96875C2.64516 16.4844 2.38281 16.7467 2.38281 17.0703C2.38281 17.3939 2.64516 17.6562 2.96875 17.6562H4.72656V19.4141C4.72656 19.7377 4.98891 20 5.3125 20C5.63609 20 5.89844 19.7377 5.89844 19.4141V17.6562H7.65625C7.97984 17.6562 8.24219 17.3939 8.24219 17.0703C8.24219 16.7467 7.97984 16.4844 7.65625 16.4844H5.89844V14.0298C6.75859 13.9342 7.55699 13.6312 8.24219 13.1716C9.0807 13.734 10.0886 14.0625 11.1719 14.0625C14.1012 14.0625 16.4844 11.6968 16.4844 8.78906C16.4844 7.54801 16.05 6.40586 15.3245 5.5041L18.8281 2.00051V4.10156C18.8281 4.42516 19.0905 4.6875 19.4141 4.6875C19.7377 4.6875 20 4.42516 20 4.10156V0.585938C20 0.262344 19.7377 0 19.4141 0ZM8.24219 11.6564C7.51773 10.9163 7.07031 9.90402 7.07031 8.78906C7.07031 7.6741 7.51773 6.6618 8.24219 5.92172C8.96664 6.6618 9.41406 7.6741 9.41406 8.78906C9.41406 9.90402 8.96664 10.9163 8.24219 11.6564ZM1.17188 8.78906C1.17188 6.52746 3.02937 4.6875 5.3125 4.6875C6.03582 4.6875 6.71586 4.87594 7.30652 5.20598C6.43324 6.14734 5.89844 7.40684 5.89844 8.78906C5.89844 10.1713 6.43324 11.4308 7.30652 12.3721C6.71586 12.7022 6.03582 12.8906 5.3125 12.8906C3.02937 12.8906 1.17188 11.0507 1.17188 8.78906ZM11.1719 12.8906C10.4486 12.8906 9.76852 12.7022 9.17785 12.3721C10.0511 11.4308 10.5859 10.1713 10.5859 8.78906C10.5859 7.40684 10.0511 6.14734 9.17785 5.20598C9.76852 4.87594 10.4486 4.6875 11.1719 4.6875C13.455 4.6875 15.3125 6.52746 15.3125 8.78906C15.3125 11.0507 13.455 12.8906 11.1719 12.8906Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2195_309083">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
