export function IconCareer({ size = 24, color = "currentColor", ...other }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" height={size} width={size} {...other}>
      <g id="vuesax/outline/award">
        <g id="award">
          <path
            id="Vector"
            d="M11.9978 22.7501C11.3978 22.7501 10.8078 22.6101 10.3278 22.3401L5.59781 19.6101C3.63781 18.2901 3.50781 18.0501 3.50781 15.9801V11.0201C3.50781 8.95006 3.63781 8.71006 5.55781 7.42006L10.3378 4.66006C11.2878 4.11006 12.7178 4.11006 13.6678 4.66006L18.3978 7.39006C20.3578 8.71006 20.4878 8.95006 20.4878 11.0201V15.9801C20.4878 18.0501 20.3578 18.2901 18.4378 19.5801L13.6578 22.3401C13.1878 22.6201 12.5878 22.7501 11.9978 22.7501ZM11.9978 5.75006C11.6578 5.75006 11.3178 5.82006 11.0778 5.96006L6.34781 8.69006C5.00781 9.60006 5.00781 9.60006 5.00781 11.0201V15.9801C5.00781 17.4001 5.00781 17.4001 6.39781 18.3401L11.0878 21.0401C11.5678 21.3201 12.4378 21.3201 12.9178 21.0401L17.6478 18.3101C18.9878 17.4001 18.9878 17.4001 18.9878 15.9801V11.0201C18.9878 9.60006 18.9878 9.60006 17.5978 8.66006L12.9078 5.96006C12.6778 5.82006 12.3378 5.75006 11.9978 5.75006Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M17.5 8.38C17.09 8.38 16.75 8.04 16.75 7.63V5C16.75 3.42 16.08 2.75 14.5 2.75H9.5C7.92 2.75 7.25 3.42 7.25 5V7.56C7.25 7.97 6.91 8.31 6.5 8.31C6.09 8.31 5.75 7.98 5.75 7.56V5C5.75 2.58 7.08 1.25 9.5 1.25H14.5C16.92 1.25 18.25 2.58 18.25 5V7.63C18.25 8.04 17.91 8.38 17.5 8.38Z"
            fill={color}
          />
          <path
            id="Vector_3"
            d="M13.6283 17.6301C13.4183 17.6301 13.1983 17.5901 12.9783 17.5001L11.9983 17.1201L11.0183 17.5101C10.4883 17.7201 9.94828 17.6701 9.54828 17.3801C9.14828 17.0901 8.93828 16.5901 8.96828 16.0201L9.02828 14.9701L8.35828 14.1601C7.99828 13.7101 7.87828 13.1901 8.03828 12.7101C8.18828 12.2401 8.59828 11.8801 9.14828 11.7401L10.1683 11.4801L10.7383 10.5901C11.3483 9.63012 12.6583 9.63012 13.2683 10.5901L13.8383 11.4801L14.8583 11.7401C15.4083 11.8801 15.8183 12.2401 15.9683 12.7101C16.1183 13.1801 15.9983 13.7101 15.6383 14.1501L14.9683 14.9601L15.0283 16.0101C15.0583 16.5801 14.8483 17.0701 14.4483 17.3701C14.2083 17.5401 13.9283 17.6301 13.6283 17.6301ZM9.51828 13.2001L10.1883 14.0101C10.4183 14.2801 10.5483 14.7101 10.5283 15.0601L10.4683 16.1101L11.4483 15.7201C11.7783 15.5901 12.2183 15.5901 12.5483 15.7201L13.5283 16.1101L13.4683 15.0601C13.4483 14.7101 13.5783 14.2901 13.8083 14.0101L14.4783 13.2001L13.4583 12.9401C13.1183 12.8501 12.7583 12.5901 12.5683 12.3001L12.0083 11.4201L11.4383 12.3001C11.2483 12.6001 10.8883 12.8601 10.5483 12.9501L9.51828 13.2001Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
